import Code from "./Code";

export default class KakaoMomentCode extends Code{

    public static readonly data = {
        options:{

            /*
            실적Level
            biz-channels
            campaigns
            ad-groups
            ads
            ad-extensions
            keywords
            creatives // 카카오
            creative-links // 카카오
            */
            ExplorerLevel:{
                campaign: {value:'campaigns', label:'캠페인'},
                adGroup: {value:'ad-groups', label:'광고그룹'},
                ad:     {value:'ads', label:'소재'},
            },
        },

        Assets:{
            /** 캠페인 목표 */
            objective: {
                APP_INSTALLS:           {value:'APP_INSTALLS',          label:'앱 설치'},
                BRAND_AWARENESS:        {value:'BRAND_AWARENESS',       label:'브랜드 인지도'},
                CONVERSIONS:            {value:'CONVERSIONS',           label:'전환'},
                EVENT_RESPONSES:        {value:'EVENT_RESPONSES',       label:'이벤트 응답'},
                LEAD_GENERATION:        {value:'LEAD_GENERATION',       label:'잠재 고객 확보'},
                LINK_CLICKS:            {value:'LINK_CLICKS',           label:'링크 클릭'},
                MOBILE_APP_INSTALLS:    {value:'MOBILE_APP_INSTALLS',   label:'모바일 앱 설치'},
                MESSAGES:               {value:'MESSAGES',              label:'메시지'},
                OFFER_CLAIMS:           {value:'OFFER_CLAIMS',          label:'쿠폰 발급'},
                PAGE_LIKES:             {value:'PAGE_LIKES',            label:'페이지 좋아요'},
                POST_ENGAGEMENT:        {value:'POST_ENGAGEMENT',       label:'게시물 참여'},
                PRODUCT_CATALOG_SALES:  {value:'PRODUCT_CATALOG_SALES', label:'카탈로그 판매'},
                REACH:                  {value:'REACH',                 label:'도달'},
                STORE_VISITS:           {value:'STORE_VISITS',          label:'매장 유입'},
                VIDEO_VIEWS:            {value:'VIDEO_VIEWS',           label:'동영상 조회'},
                OUTCOME_APP_PROMOTION:  {value:'OUTCOME_APP_PROMOTION', label:'앱 홍보'},
                OUTCOME_ENGAGEMENT:     {value:'OUTCOME_ENGAGEMENT',    label:'참여'},
                OUTCOME_TRAFFIC:        {value:'OUTCOME_TRAFFIC',       label:'트래픽'},
                OUTCOME_AWARENESS:      {value:'OUTCOME_AWARENESS',     label:'인지도'},
                OUTCOME_SALES:          {value:'OUTCOME_SALES',         label:'판매'},
                OUTCOME_LEADS:          {value:'OUTCOME_LEADS',         label:'잠재 고객'},
            },
            /** 트래픽 유도 위치 */
            destinationType: {
                ON_AD:              {value:'ON_AD',                 label:'인스턴트 양식'},
                WEBSITE:            {value:'WEBSITE',               label:'웹사이트'},
                APP:                {value:'APP',                   label:'앱'},
                MESSENGER:          {value:'MESSENGER',             label:'Messenger'},
                ON_REMAINDER:       {value:'ON_REMAINDER',          label:'광고'},
                ON_PAGE:            {value:'ON_PAGE',               label:'Facebook 페이지'},
                PHONE_CALL:         {value:'PHONE_CALL',            label:'통화'},
                ON_VIDEO:           {value:'ON_VIDEO',              label:'광고'},
                ON_POST:            {value:'ON_POST',               label:'광고'},
                INSTAGRAM_DIRECT:   {value:'INSTAGRAM_DIRECT',      label:'Instagram Direct'},
                INSTAGRAM_PROFILE:  {value:'INSTAGRAM_PROFILE',     label:'Instagram 프로필'},
                UNDEFINED:          {value:'UNDEFINED',             label:'웹사이트'},
                APPLINKS_AUTOMATIC: {value:'APPLINKS_AUTOMATIC',    label:'앱이 자동으로 선택됨'},
                FACEBOOK:           {value:'FACEBOOK',              label:'Facebook'},
                MESSAGING_INSTAGRAM_DIRECT_MESSENGER:           {value:'MESSAGING_INSTAGRAM_DIRECT_MESSENGER',          label:'메시지 앱'},
                MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP:  {value:'MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP', label:'메시지 앱'},
                MESSAGING_INSTAGRAM_DIRECT_WHATSAPP:            {value:'MESSAGING_INSTAGRAM_DIRECT_WHATSAPP',           label:'메시지 앱'},
                MESSAGING_MESSENGER_WHATSAPP:                   {value:'MESSAGING_MESSENGER_WHATSAPP',                  label:'메시지 앱'},
                ON_EVENT:           {value:'ON_EVENT',              label:'광고'},
                SHOP_AUTOMATIC:     {value:'SHOP_AUTOMATIC',        label:''},
                WHATSAPP:           {value:'WHATSAPP',              label:'Whatsapp'},
            },

        }
    };

    
};

