import clsx from "clsx";
import { useState } from "react";
import { OptionType } from "../../../../modules/types/Types";
import { EstimateAccountGroupType } from "../../../common/services/models/admin/EstimateTypes";
import { DashBoardStatReportDataType, DashBoardStatTableClientResType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import AdminCode from "../../../modules/code/AdminCode";
import CustomBaseCard from "../../modules/card/CustomBaseCard";
import MediaTypeIcon from "../../modules/symbol/MediaTypeIcon";
import { mergeDashboardReportData } from "./ReportDashboardData";
import styles from './style.module.scss';

export enum SelectTypeKey{
    none = 'none',
    media = 'media',
    client='client'
}

interface ReportTableProps{
    info?: EstimateAccountGroupType;
    value?:DatshBoardStatTableType;
    loading?:boolean;
}

interface TableDataType extends OptionType{
    clients: DashBoardStatTableClientResType[];
    week1: DashBoardStatReportDataType;
    week2: DashBoardStatReportDataType;
}

const ReportTable:React.FC<ReportTableProps> = (props)=>{

    //매체별로 분리
    const data:TableDataType[] = AdminCode.getOptions(AdminCode.data.Assets.mediaType).map((v1)=>{
        return {
            ...v1,
            clients: props.value?.clients.filter((v2)=>v2.mediaType === v1.code || v2.mediaType.toLocaleLowerCase() === v1.value) || [],
        }
    }).filter((v)=>v.clients?.length > 0)
    .map((v1)=>({
        ...v1,
        week1: mergeDashboardReportData(v1.clients.map((v2)=>v2.week1)),
        week2: mergeDashboardReportData(v1.clients.map((v2)=>v2.week2)),
    }))

    return <CustomBaseCard title="매체(상품)별 상세">
        <div style={{maxWidth: 'calc(100vw - var(--bs-app-sidebar-width) - 5em - 60px)', overflowX:'scroll'}}> 
            <table className={clsx(styles.ReportTable)}>
                <thead>
                    <tr>
                        <th></th>
                        <th>매체/자산명</th>
                        <th>노출수</th>
                        <th>클릭수</th>
                        <th>비용</th>
                        <th>조회수</th>
                        <th>구매 전환수</th>
                        <th>구매 전환 매출</th>
                        <th>CTR</th>
                        <th>VTR</th>
                        <th>CVR(구매)</th>
                        <th>CPM</th>
                        <th>CPC</th>
                        <th>CPV</th>
                        <th>ROAS</th>
                    </tr>
                </thead>

                <tbody>
                    {data.map((v, index)=><MediaTypeTable key={index} value={v}/>)}
                </tbody>

            </table>
        </div>
    </CustomBaseCard>
}
export default ReportTable;

const MediaTypeTable:React.FC<{value:TableDataType}> = (props)=>{
    const [show, setShow] = useState<boolean>(false);

    return <>
        <tr className={styles.mediaType}>
            <td>
                {show && <i className="bi bi-chevron-up cursor-pointer" onClick={()=>setShow(false)}/>}
                {!show && <i className="bi bi-chevron-down cursor-pointer" onClick={()=>setShow(true)}/>}
            </td>
            <td>
                <MediaTypeIcon code={props.value.code}/>
                {props.value.label}
            </td>
            <td><CellCountView target={props.value.week1.impressions} base={props.value.week2.impressions} /></td>
            <td><CellCountView target={props.value.week1.clicks} base={props.value.week2.clicks} /></td>
            <td><CellCountView target={props.value.week1.cost} base={props.value.week2.cost} /></td>
            <td><CellCountView target={props.value.week1.videoView} base={props.value.week2.videoView} /></td>
            <td><CellCountView target={props.value.week1.purchase} base={props.value.week2.purchase} /></td>
            <td><CellCountView target={props.value.week1.purchaseValue} base={props.value.week2.purchaseValue} /></td>
            <td><CellCountView target={props.value.week1.ctr} base={props.value.week2.ctr} showPercent={true} round={2}/></td>
            <td><CellCountView target={props.value.week1.vtr} base={props.value.week2.vtr} showPercent={true} round={2}/></td>
            <td><CellCountView target={props.value.week1.cvr} base={props.value.week2.cvr} showPercent={true} round={2}/></td>
            <td><CellCountView target={props.value.week1.cpm} base={props.value.week2.cpm} /></td>
            <td><CellCountView target={props.value.week1.cpc} base={props.value.week2.cpc} /></td>
            <td><CellCountView target={props.value.week1.cpv} base={props.value.week2.cpv} /></td>
            <td><CellCountView target={props.value.week1.roas} base={props.value.week2.roas} /></td>
        </tr>
        {props.value.clients.map((v,index)=><tr key={index} className={clsx(styles.client, {[styles.show]:show})}>
            <td></td>
            <td>{v.clientName}</td>
            <td><CellCountView target={v.week1.impressions} base={v.week2.impressions} /></td>
            <td><CellCountView target={v.week1.clicks} base={v.week2.clicks} /></td>
            <td><CellCountView target={v.week1.cost} base={v.week2.cost} /></td>
            <td><CellCountView target={v.week1.videoView} base={v.week2.videoView} /></td>
            <td><CellCountView target={v.week1.purchase} base={v.week2.purchase} /></td>
            <td><CellCountView target={v.week1.purchaseValue} base={v.week2.purchaseValue} /></td>
            <td><CellCountView target={v.week1.ctr} base={v.week2.ctr} showPercent={true} round={2}/></td>
            <td><CellCountView target={v.week1.vtr} base={v.week2.vtr} showPercent={true} round={2}/></td>
            <td><CellCountView target={v.week1.cvr} base={v.week2.cvr} showPercent={true} round={2}/></td>
            <td><CellCountView target={v.week1.cpm} base={v.week2.cpm} /></td>
            <td><CellCountView target={v.week1.cpc} base={v.week2.cpc} /></td>
            <td><CellCountView target={v.week1.cpv} base={v.week2.cpv} /></td>
            <td><CellCountView target={v.week1.roas} base={v.week2.roas} /></td>
        </tr>)}
    </>
}




const CellCountView:React.FC<{title?:React.ReactNode|string, base?:number, target?:number, showPercent?:boolean, round?:number}> = (props)=>{
    const unit:string|undefined = props.showPercent===true ? "%" : undefined;
    const time:number = props.showPercent ? 100 : 1;
    const target:number = ((props.target || 0) * time).round(props.round || 0);
    const base:number = ((props.base || 0) * time).round(props.round || 0);

    return <>
        <div className="align-items-baseline text-right">
            <span className="fs-7 fw-bolder text-right">{target.addComma()}{unit}</span>
            <BadgeDiffPer target={target} base={base} />
        </div>
        <div className="text-gray-400">비교기간 {base.addComma()}{unit}</div>
    </>
}


/** 변동율을 배찌로 표시합니다. */
const BadgeDiffPer:React.FC<{base:number, target:number}> = (props)=>{
    const term:number = props.target - props.base;
    const arrow:string = term === 0 ? '' : term < 0 ? '↓' :'↑';
    const color:string = term === 0 ? 'light' : term < 0 ? 'text-danger' :'text-success';
    return <div className={clsx("fs-8 ps-1", color)} style={{display:'inline-block', width:'inherit'}}>
        ({arrow} {(Math.abs(term)/(props.base || 1)*100).toFixed(1)+'%'})
    </div>
}

