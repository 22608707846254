/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react';
import {ILayout, useLayout} from '../../core';

const Footer = () => {
    const {config} = useLayout();
    useEffect(() => {
        updateDOM(config);
    }, [config]);

    const year = new Date().getFullYear();
    return (
        <>
            <div className='text-dark order-2 order-md-1'>
                <span className='text-muted fw-semibold me-1'>
                    Copyright {year}. MezzoMedia Inc. All Rights Reserved. v
                    {process.env.REACT_APP_VERSION}
                </span>
                {/* <span className='btn-label'>v{process.env.REACT_APP_VERSION}{Config.project.mode && `(${Config.project.mode})`}</span> */}
            </div>

            {/* <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <a href='http://dbase.co.kr/' target='_blank' className='menu-link px-2'>
            About
          </a>
        </li>

      </ul> */}
        </>
    );
};

const updateDOM = (config: ILayout) => {
    if (config.app?.footer?.fixed?.desktop) {
        document.body.classList.add('data-kt-app-footer-fixed', 'true');
    }

    if (config.app?.footer?.fixed?.mobile) {
        document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true');
    }
};

export {Footer};
