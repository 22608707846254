
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/meta/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { SortKeys } from '../../modules/table/Table';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        ctr:                    StatFormatter.divide(row?.impressions,  row?.clicks,        100 )?.round(3),
        cpc:                    StatFormatter.divide(row?.cost,         row?.clicks             )?.round(3),
        cpm:                    StatFormatter.divide(row?.cost,         row?.impressions,   1000)?.round(3),
        cpr:                    StatFormatter.divide(row?.cost,         row?.reach,         1000)?.round(3),
        uniqueCtr:              StatFormatter.divide(row?.uniqueClicks, row?.reach,         100 )?.round(3),
        costPerUniqueClick:     StatFormatter.divide(row?.cost,         row?.uniqueClicks       )?.round(3),
        costPerPageLike:        StatFormatter.divide(row?.cost,         row?.like               )?.round(3),
        costPerVideoView3s:     StatFormatter.divide(row?.cost,         row?.videoPlay3s        )?.round(3),
        costPerVideoView15s:    StatFormatter.divide(row?.cost,         row?.thruPlay           )?.round(3),
        costPerInstall:         StatFormatter.divide(row?.cost,         row?.installs           )?.round(3),
    }
}



export const addVat = (value?:number):number => {
    return  Math.round((value || 0) * 1.1);
}
// 이동 예정
export class StatFormatter{
    static comma(value?:number):string{ return value?.round().addComma() || '0'; }
    static commaPer(value?:number):string{ if(value){ value *= 100; } return (value?.round() || 0).addComma()+'%'; }
    static round(value?:number):string{ return (value?.round(1) || 0).addComma(); }
    static roundPer(value?:number):string{ if(value){ value *= 100; } return (value?.round(1) || 0).addComma()+'%'; }

    /**
     * 인자값 나눗셈을 합니다. - undefined 처리
     * @param value 값
     * @param by 나누기
     * @param times 값에 곱 - 퍼센트 처리 시 100을 입력합니다.
     * @returns 
     */
    static divide(value?:number, by?:number, times?:number):number{
        if(value===undefined || !by){ return 0; }
        if(times){ value *= times; }
        return value/by;
    }

    
    static cpa(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.round(value);
    }
    
    static addVatComma(value?:number, row?:StatReportResType, index?:number):string{
        return addVat(value)?.addComma();
    }
}

const headerStyle:React.CSSProperties = {};//{backgroundColor:'rgba( var(--bs-warning-rgb), 0.1)'};
const colStyle:React.CSSProperties ={minWidth:'100px', textAlign: 'right'};
export const defaultProps:HeaderColumn = {
    accessor:'',
    colGroup:'성과 Data',
    style:colStyle,
    headerStyle:headerStyle,
    useSort:false,
    formatter: StatFormatter.comma,
    defaultSortDirection:SortKeys.Desc, //기본 정렬 내림차순 
}

export const OxfordColumns:HeaderColumn[] = [
    { ...defaultProps, accessor: StatReportResKeys.clicks,                  header: '클릭수',               unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.frequency,               header: '빈도',                 unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.impressions,             header: '노출',                 unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.reach,                   header: '도달',                 unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.result,                  header: '결과',                 unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.cost,                    header: '지출 금액',             unit:'원', },

    { ...defaultProps, accessor: StatReportResKeys.ctr,                     header: '클릭률(CTR)',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.cpc,                     header: '클릭당 비용(CPC)',     unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpm,                     header: '1,000g회당 비용(CPM)',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpr,                     header: '계정 센터 계정 1,000개 도달당 비용',   unit:'원', },

    { ...defaultProps, accessor: StatReportResKeys.uniqueClicks,            header: '고유 클릭(전체)',      unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.linkClick,               header: '링크 클릭',            unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.postEngagement,          header: '게시물 참여',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.postComment,             header: '게시물 댓글',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.postSave,                header: '게시물 저장',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.postShare,               header: '게시물 공유',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.postReaction,            header: '게시물 공감',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.pageEngagement,          header: '페이지 참여',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.like,                    header: '팔로우 또는 좋아요',      unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.photoView,               header: '사진 조회',            unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView25,  header: '25%', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView50,  header: '50%', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView75,  header: '75%', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView95,  header: '95%', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoViewEnd,  header: '100%', unit:'회', },
    { ...defaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay3s,  header: '3초', unit:'회', },
    { ...defaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.thruPlay,     header: '15초', unit:'회', },

    { ...defaultProps, accessor: StatReportResKeys.uniqueCtr,                   header: '고유 CTR(전체)',            unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.costPerUniqueClick,          header: '고유 클릭당 비용(전체)',       unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.costPerPageLik,              header: '팔로우 또는 좋아요당 비용',     unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.costPerVideoView3s,          header: '동영상 3초 이상 재생당 비용',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.costPerVideoView15s,         header: '동영상 15초 이상 재생당 비용',  unit:'원', },

    { ...defaultProps, accessor: StatReportResKeys.purchase,                    header: '구매',               unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.purchaseValue,               header: '구매 전환값',          unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.webPurchase,                 header: '웹사이트 구매',        unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.webPurchaseValue,            header: '웹사이트 구매 전환깂',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.appMobilePurchase,           header: '앱 내 구매',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.appMobilePurchaseValue,      header: '앱 내 구매 전환값',     unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.facebookPurchase,            header: 'Meta 구매',          unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.facebookPurchaseValue,       header: 'Meta 구매 전환값',    unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.completeRegistration,        header: '등록 완료',           unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.webCompleteRegistration,     header: '웹사이트 등록 완료',    unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.appMobileCompleteRegistration,header: '앱 내 등록 완료',     unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.installs,                    header: '앱 설치',             unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.installsPc,                  header: '데스크톱 앱 설치',      unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.installsMobile,              header: '모바일 앱 설치',        unit:'회', },

    { ...defaultProps, accessor: StatReportResKeys.addToCart,                   header: '장바구니에 담기',        unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.webAddToCart,                header: '웹사이트 장바구니에 담기', unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.appMobileAddToCart,          header: '앱 내 장바구니에 담기',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.activateApp,                 header: '앱 활성화',            unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.appMobileActivateApp,        header: '앱 내 세션',           unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.costPerInstall,              header: '앱 설치당 비용',        unit:'회', },
];

const localVatFormatter = (value: number, row: StatReportResType)=>StatFormatter.addVatComma(value,row) || 0;

/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalOxfordColumns:HeaderColumn[] = [
    ...OxfordColumns.map((v):HeaderColumn=>({...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}))
];

