
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/kakaomoment/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { SortKeys } from '../../modules/table/Table';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        ctr:            StatFormatter.divide(row?.clicks,       row?.impressions  )?.round(3),
        vtr:            StatFormatter.divide(row?.videoView,    row?.impressions  )?.round(3),
        cpc:            StatFormatter.divide(row?.cost,         row?.clicks       )?.round(3),
        cpi:            StatFormatter.divide(row?.cost,         row?.impressions  )?.round(3),
        cpm:            StatFormatter.divide(row?.cost * 1000,  row?.impressions  )?.round(3),
        cpr:            StatFormatter.divide(row?.cost,         row?.reach        )?.round(3),
        cpv:            StatFormatter.divide(row?.cost,         row?.videoView    )?.round(3),
        msgOpenRate:    StatFormatter.divide(row?.messageOpen,  row?.messageSend  )?.round(3),
        msgClickRate:   StatFormatter.divide(row?.messageClick, row?.messageSend  )?.round(3),
        cpms:           StatFormatter.divide(row?.cost,         row?.messageSend  )?.round(3),
        cpmo:           StatFormatter.divide(row?.cost,         row?.messageOpen  )?.round(3),
        cpmc:           StatFormatter.divide(row?.cost,         row?.messageClick )?.round(3),
        cpckpa:         StatFormatter.divide(row?.cost,         row?.convKfPfAdd7d)?.round(3),
    }
}



export const addVat = (value?:number):number => {
    return  Math.round((value || 0) * 1.1);
}
// 이동 예정
export class StatFormatter{
    static comma(value?:number):string{ return value?.round().addComma() || '0'; }
    static commaPer(value?:number):string{ if(value){ value *= 100; } return (value?.round() || 0).addComma()+'%'; }
    static round(value?:number):string{ return (value?.round(1) || 0).addComma(); }
    static roundPer(value?:number):string{ if(value){ value *= 100; } return (value?.round(1) || 0).addComma()+'%'; }

    /**
     * 인자값 나눗셈을 합니다. - undefined 처리
     * @param value 값
     * @param by 나누기
     * @param times 값에 곱 - 퍼센트 처리 시 100을 입력합니다.
     * @returns 
     */
    static divide(value?:number, by?:number, times?:number):number{
        if(value===undefined || !by){ return 0; }
        if(times){ value *= times; }
        return value/by;
    }

    
    static cpa(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.round(value);
    }
    
    static addVatComma(value?:number, row?:StatReportResType, index?:number):string{
        return addVat(value)?.addComma();
    }
}

const headerStyle:React.CSSProperties = {};//{backgroundColor:'rgba( var(--bs-warning-rgb), 0.1)'};
const colStyle:React.CSSProperties ={minWidth:'100px', textAlign: 'right'};
export const defaultProps:HeaderColumn = {
    accessor:'',
    colGroup:'성과 Data',
    style:colStyle,
    headerStyle:headerStyle,
    useSort:false,
    formatter: StatFormatter.comma,
    defaultSortDirection:SortKeys.Desc, //기본 정렬 내림차순 
}

export const OxfordColumns:HeaderColumn[] = [
    { ...defaultProps, accessor: StatReportResKeys.clicks,              header: '클릭수',       unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.impressions,         header: '노출수',       unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.reach,               header: '도달수',      unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.cost,                header: '비용',         unit:'원', },
    { ...defaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay10s,  header: '10초', unit:'회', },
    { ...defaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay15s,  header: '15초', unit:'회', },
    { ...defaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay30s,  header: '30초', unit:'회', },
    { ...defaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay60s,  header: '60초', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView25,  header: '25%', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView50,  header: '50%', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView75,  header: '75%', unit:'회', },
    { ...defaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView100,  header: '100%', unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.videoPlayAuto,       header: '자동 재생수',         unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.videoPlayTouch,      header: '터치 재생수',         unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.videoUnmute,         header: '사운드 ON수',         unit:'회', },

    { ...defaultProps, accessor: StatReportResKeys.ctr,                 header: '클릭률(CTR)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.vtr,                 header: '조회율(VTR)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.cpc,                 header: '클릭당 비용(CPC)',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpi,                 header: '노출당 비용',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpm,                 header: '1,000g회당 비용(CPM)',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpr,                 header: '도달당 비용',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpv,                 header: '조회당 비용',   unit:'원', },

    { ...defaultProps, accessor: StatReportResKeys.messageSend,         header: '메세지 발송수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageOpen,         header: '메세지 열람수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClick,        header: '메세지 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickShare,   header: '공유 버튼 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickMedia,   header: '이미지/동여상 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickTitle,   header: '타이틀 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickText,    header: '텍스트 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickList1,   header: '리스트1 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickList2,   header: '리스트2 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickList3,   header: '리스트3 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickList4,   header: '리스트4 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickButton1, header: '버튼1 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickButton2, header: '버튼2 클릭수',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.messageClickOthers,  header: '기타 클릭수',   unit:'회', },

    { ...defaultProps, accessor: StatReportResKeys.msgOpenRate,         header: '열람률',       unit:'%', },
    { ...defaultProps, accessor: StatReportResKeys.msgClickRate,        header: '메세지 클릭률',   unit:'%', },
    { ...defaultProps, accessor: StatReportResKeys.cpms,                header: '발송당 비용',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpmo,                header: '열람당 비용',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpmc,                header: '전체 클릭당 비용',   unit:'원', },

    { ...defaultProps, accessor: StatReportResKeys.convKfPfAdd1d,       header: '친구 추가수(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convKfPfAdd7d,       header: '친구 추가수(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convCmptReg1d,       header: '가입 완료(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convCmptReg7d,       header: '가입 완료(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convViewCart1d,      header: '장바구니 보기(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convViewCart7d,      header: '장바구니 보기(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convPurchase1d,      header: '구매(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convPurchase7d,      header: '구매(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convParticipation1d, header: '잠재고객(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convParticipation7d, header: '잠재고객(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convSignUp1d,        header: '서비스 신청(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convSignUp7d,        header: '서비스 신청(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convAppInstall1d,    header: '앱 설치(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convAppInstall7d,    header: '앱 설치(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convPurchaseP1d,     header: '구매 금액(1일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.convPurchaseP7d,     header: '구매 금액(7일)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.cpckpa,              header: '친구 추가 수당 비용(7일)',   unit:'원', },
];

const localVatFormatter = (value: number, row: StatReportResType)=>StatFormatter.addVatComma(value,row) || 0;

/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalOxfordColumns:HeaderColumn[] = [
    ...OxfordColumns.map((v):HeaderColumn=>({...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}))
];

