import React, { CSSProperties, useEffect, useState } from "react";
import { KakaoMomentService } from "../../../common/services";
import { StatReportInfoType, StatReportResKeys, StatReportResType } from "../../../common/services/models/kakaomoment/StatReportType";
import KakaoMomentCode from "../../../modules/code/KakaoMomentCode";
import { LoadingLayer } from "../../../modules/modal/LoadingModal";
import Table from "../../modules/table/Table";
import { HeaderColumn, TablePropsType } from "../../modules/table/TableType";
import { OxfordColumns, StatDataExtends } from "../modules/OxfordColumns";
import ExplorerChart from "./ExplorerChart";
import { getStatDateFormatter, getStatReportTotal } from "./StatInfoData";

interface ExplorerListDetailProps{
    level?:string;
    id?:string;
    data: StatReportInfoType;
    className?:string;
    style?:CSSProperties;
}
const ExplorerListDetail:React.FC<ExplorerListDetailProps> = (props) => {
    const [data, setData] = useState<StatReportResType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(()=>{
        if(props.level && props.id){
            setLoading(true);
            const dimensionInfo = getDimensionInfo(props.id, props.data, props.data.campaignName);   //디멘전정보 추출
            
            KakaoMomentService.performance(props.level, dimensionInfo.key).then((res)=>{
                const data:any[] = res?.data?.result?.daily?.map((v:StatReportResType)=>StatDataExtends(v)) || [];  //일부러 any처리함 - 아래 빠진 일자데이터 추가
                const dateRange = KakaoMomentService.common.getDateRange();
                const days = dateRange.end.dateDiff(dateRange.start);
                const result:any[] = [];
                for(let i=0; i<=days; i++){
                    const date = new Date(dateRange.start).addDays(i).format('yyyy-MM-dd');
                    result.push({
                        key: date,
                        ...data.find((v)=>v.key === date)
                    });
                }
                setData(result);
            })
            .finally(()=>{setLoading(false);});
        }
    },[props.level, props.id]);
    

    const columns:HeaderColumn[] = [
        { accessor: StatReportResKeys.key, header: '날짜', style:{minWidth:'100px', textAlign: 'center'}, useSort:false, formatter:getStatDateFormatter, },
        ...OxfordColumns,
    ];

    const tableProps:TablePropsType<StatReportResType> = {
        columns:columns,
        data: data,
        filterable: false,
        showColumns:false,
        showDeviceType: false,
        pageable:false,
        staticData: [getStatReportTotal(data)],
    }

    return <div className={props.className} style={props.style}>
        {loading && <LoadingLayer className='border bg-gray-100 pt-20 pb-20 text-secondary' variant='secondary' style={{paddingLeft:'350px'}} />}
        {!loading && <>
            <ExplorerChart data={data} />
            <Table {...tableProps} />
        </>}
    </div>
}

export default ExplorerListDetail;


export interface DimensionInfoType{
    key: string;
    dimentsion?: string;
    dimentsionWord?: string;
    name: string;
}
/** Explorer 조회 데이터 기반으로 디멘전 추정 */
export const getDimensionInfo = (id?:string, data?: StatReportInfoType, name?:string, search?:string):DimensionInfoType=>{
    if( search && search?.indexOf('-') !== -1){
        const token = search.split('-');
        return {
            key: search,
            dimentsion: token[0],
            dimentsionWord: token[2],
            name: token[2] || name || "",
        };
    }
    let dimentsion:undefined|string =undefined;
    let dimentsionWord:undefined|string =undefined;
    if(data?.device){
        dimentsion = KakaoMomentCode.data.options.dimensionType.device.value;
        dimentsionWord = data.device;
    }
    if(data?.placement){
        dimentsion = KakaoMomentCode.data.options.dimensionType.placement.value;
        dimentsionWord = data.placement;
    }
    if(data?.creativeFormat){
        dimentsion = KakaoMomentCode.data.options.dimensionType.creative_format.value;
        dimentsionWord = data.creativeFormat;
    }
    if(data?.location){
        dimentsion = KakaoMomentCode.data.options.dimensionType.location.value;
        dimentsionWord = data.location;
    }

    return {
        key: [dimentsion, id, dimentsionWord].join("-"),
        dimentsion,
        dimentsionWord,
        name: dimentsionWord || name || "",
    };
}