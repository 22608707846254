import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'

const SidebarLogo = () => {
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : '';
    
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default;
  return (
    <div className='app-sidebar-logo px-5' id='kt_app_sidebar_logo' >
    {/* <div className='app-sidebar-logo px-5' id='kt_app_sidebar_logo'> */}
      <Link to='/dashboard' style={{display:"flex"}}>
        <div
          className='app-sidebar-logo-default'
          style={{ 
            textAlign:"center",
            fontSize:"1.75em", 
            fontWeight:"bold", 
            // color: fontColor,
          }}
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/images/adplo.png')}
            width='120px'
            className='app-sidebar-logo-default'
          />
        </div>
        {/* <span className='h-20px app-sidebar-logo-default mt-1' 
          style={{
            fontSize:"2.3em", 
            fontWeight:"bold", 
            margin:"-0.4em 0 0 0.4em", 
            color : fontColor,
          }}>AdPlo</span> */}

        {/* <div className='app-sidebar-logo-minimize' style={{width:"18px", overflow:"hidden", marginLeft:"0.55em"}}>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/images/dbase_logo.png')}
            className='h-20px app-sidebar-logo-minimize'
          />
        </div> */}
        
        <div className='h-40px w-40px app-sidebar-logo-minimize'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/images/adplo_square.png')}
            width='40px'
            className='app-sidebar-logo-minimize'
          />
        </div>
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
        </div>
      )}
    </div>
  )
}

export { SidebarLogo }

