import { KakaoService, NaverService } from "../../../common/services";
import { EstimateAccountGroupType } from "../../../common/services/models/admin/EstimateTypes";
import { DashBoardStatClientResType, DashBoardStatReportDataType, DatshBoardStatQryType, DatshBoardStatResType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import { StatReportResType as KakaoStatReportResType } from "../../../common/services/models/kakao/StatReportType";
import { StatReportResType as NaverStatReportResType } from "../../../common/services/models/naver/StatReportType";
import { UserContextModel } from "../../../contexts/UserContext";
import { StatFormatter } from "../../naver/modules/NaverOxfordColumns";


export const loadStatResportNaver = async (id:number, startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: [id.toString()],
    };
        
    return await NaverService.dashboard.getStat(body)
        .then((res):DatshBoardStatResType[]=>{
            const tmp:DatshBoardStatResType<NaverStatReportResType>[] = res.data.result;
            const tmp2 = tmp.map((v1):DatshBoardStatResType=>({
                accountGroupId: v1.accountGroupId,
                accountGroupName: v1.accountGroupName,
                roleAuth: v1.roleAuth,
                accountGroupUseYn: v1.accountGroupUseYn,
                bookmarkYn: v1.bookmarkYn,
                bookmarkOrder: v1.bookmarkOrder,
                clients: v1.clients?.map((v2):DashBoardStatClientResType=>({
                    ...v2,
                    stats: v2.stats?.map((v3):DashBoardStatReportDataType=>({
                        key: v3.key,
                        impressions: v3.impressionCount,
                        clicks: v3.clickCount,
                        cost: (v3.adCost * 1.1).toFixed(0).toNumber(),
                        purchase: v3.dcPurchaseCount + v3.icPurchaseCount,
                        purchaseValue: v3.dcPurchaseValue + v3.icPurchaseValue,
                        videoView: 0,
                    })) || [],
                    total: undefined,
                }))
            }));

            return totalDashboardReportDataExtension(tmp2);
        })
        .catch((e) => {
            userContext.alertMessage.add({variant:"danger", title:"네이버 성과데이터 조회 오류", body:`네이버 성과데이터 조회 중 오류가 발생했습니다. ${NaverService.ErrorMessage(e)}`});
            return [];
        });
}
   
export const loadStatResportKakao= async (id:number, startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: [id.toString()],
    };
        
    // userContext.modalLoading.show();
    return await KakaoService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<KakaoStatReportResType>[] = res.data.result;
        const tmp2 = tmp.map((v1)=>({
            accountGroupId: v1.accountGroupId,
            accountGroupName: v1.accountGroupName,
            roleAuth: v1.roleAuth,
            accountGroupUseYn: v1.accountGroupUseYn,
            bookmarkYn: v1.bookmarkYn,
            bookmarkOrder: v1.bookmarkOrder,
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressions: v3.impressionCount,
                    clicks: v3.clickCount,
                    cost: v3.adCost,
                    purchase: v3.cvPurchase7D,
                    purchaseValue: v3.cvPurchasePrice7D,
                    videoView: 0,
                })) || [],
                total: undefined,
            })) || [],
        }));
        return totalDashboardReportDataExtension(tmp2);
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title:"카카오 성과데이터 조회 오류", body:`카카오 성과데이터 조회 중 오류가 발생했습니다. ${KakaoService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}

   
export const mergeWeekData = (accountGroup:EstimateAccountGroupType, week1:DashBoardStatClientResType[], week2:DashBoardStatClientResType[]):DatshBoardStatTableType=>{
    // if(week1.length === 0 && week2.length ===0){ return undefined; }    //데이터 없음
    return {
        ...accountGroup,
        clients: accountGroup.clients.map(v1=>{
            const w1 = week1.find(v2=>v2.clientId.toString()===v1.clientId.toString() && v2.mediaType==v1.mediaType)?.stats || [];
            const w2 = week2.find(v2=>v2.clientId.toString()===v1.clientId.toString() && v2.mediaType==v1.mediaType)?.stats || [];
            return {
                ...v1,
                week1: mergeDashboardReportData(w1),
                week2: mergeDashboardReportData(w2),
            }
        })
    };
}


/** 배열데이터를 모두 합하여 반환합니다. */
export const mergeDashboardReportData = (value:(DashBoardStatReportDataType|undefined)[]):DashBoardStatReportDataType => {
    const data = value.reduce((prev:DashBoardStatReportDataType, v):DashBoardStatReportDataType=>{
        if(v === undefined){ return prev; }
        return {
            key : prev.key,
            impressions : prev.impressions + v.impressions,
            clicks: prev.clicks + v.clicks,
            cost: prev.cost + v.cost,
            purchase: prev.purchase + v.purchase,
            purchaseValue: prev.purchaseValue + v.purchaseValue,
            videoView: prev.videoView + v.videoView,
        }
    }, defaultDashboradReportData());
    const data2 = statDashboardReportDataExtension(data);
    return  data2;
}

/** 불필요한 연산을 줄이기 위해 빈값을 지정 */
export const defaultDashboradReportData = ():DashBoardStatReportDataType=>({
    key : '',
    impressions : 0,
    clicks : 0,
    cost : 0,
    purchase : 0,
    purchaseValue : 0,
    videoView : 0,
    ctr : 0,
    cvr : 0,
    vtr : 0,
    roas : 0,
    cpc : 0,
    cpm : 0,
    cpv : 0,
});


export const statDashboardReportDataExtension = (value:DashBoardStatReportDataType):DashBoardStatReportDataType =>({
    ...value,
    ctr : StatFormatter.divide(value?.clicks, value?.impressions),
    cvr : StatFormatter.divide(value?.purchase, value?.clicks),
    vtr : StatFormatter.divide(value?.videoView, value?.clicks),
    roas : StatFormatter.divide(value?.purchaseValue, value?.clicks),
    cpc : StatFormatter.divide(value?.cost, value?.clicks),
    cpm : StatFormatter.divide(value?.cost, value?.impressions, 1000),
    cpv : StatFormatter.divide(value?.cost, value?.videoView),
});

/** total 정보 확장 */
export const totalDashboardReportDataExtension = (value:DatshBoardStatResType[]) =>{
    const data = value.map((v1):DatshBoardStatResType=>({
        ...v1,
        clients: v1.clients?.map((v2):DashBoardStatClientResType=>({
            ...v2,
            total: mergeDashboardReportData(v2.stats), //device로 나뒨 데이터를 합합니다.
        }))
    }));
    return data.map((v1):DatshBoardStatResType=>({
        ...v1,
        total: mergeDashboardReportData(v1.clients.map((v2)=>v2.total)), //client 레벨 데이터를 모두 합합니다.
    }));
}