import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { DashBoardStatClientResType, DatshBoardStatResType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import { EstimateAccountGroupType } from '../../../common/services/models/admin/EstimateTypes';
import { useUserContext } from "../../../contexts/UserContext";
import AccountGroupSelect from '../../components/account/AccountGroupSelect';
import { DateRangeDataType } from "../../modules/calendar/DateRangePicker";
import DashboardDateRange from "./DashboardDateRange";
import ReportCard from "./ReportCard";
import { loadStatResportGoogle, loadStatResportKakaoMoment, loadStatResportMeta, loadStatResportNaverGfa, loadStatResportNaverNosp } from "./ReportDashboardDaData";
import { loadStatResportKakao, loadStatResportNaver, mergeWeekData } from "./ReportDashboardData";
import ReportNone from "./ReportNone";
import ReportTable from "./ReportTable";


interface ReportDashboardProps{
}

const defaultRange = {
    week1 :{
        startDate: new Date().addDays(-14),
        endDate: new Date().addDays(-1)
    },
    week2 :{
        startDate: new Date().addDays(-28),
        endDate: new Date().addDays(-15)
    },
}

const ReportDashboard:React.FC<ReportDashboardProps> = (props)=>{
    const userContext = useUserContext();
    const [useStatData, setUseStatData] = useState<DatshBoardStatTableType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [useAccountGroup, setUseAccountGroup] = useState<EstimateAccountGroupType|undefined>();
    const [dateRange1, setDateRange1] = useState<DateRangeDataType>(defaultRange.week1);
    const [dateRange2, setDateRange2] = useState<DateRangeDataType>(defaultRange.week2);

    useEffect(()=>{
        setUseAccountGroup(userContext.userSelect.accountState?.account?.accountGroup);
    }, [userContext.userSelect.accountState?.account?.accountGroup]);

    // 광고주 선택을 안한 경우
    useEffect(()=>{
        setUseAccountGroup((prev)=>{
            return prev || userContext.userSelect.accountState?.list?.[0];  // 선택정보 읽기와 데이터 로딩간의 시간차 고려, undefined일 때만 리스트로부터 가저옴
        });
    }, [userContext.userSelect.accountState?.list]);


    const loadStatResport = async (data?:EstimateAccountGroupType, lock?:boolean, week1?:DateRangeDataType, week2?:DateRangeDataType)=>{
        data = data || useAccountGroup;
        if(!data){ return; }    //광고주정보가 없는 경우
        if(!lock && useStatData.find((v)=>v.magId===data?.magId)){ return; }  // 이미 로딩된 데이터가 있는 경우,

        week1 = week1 || dateRange1;
        week2 = week2 || dateRange2;
        
        setLoading(true);
        const [
            naver1,     naver2,
            naver_gfa1, naver_gfa2,
            naver_nosp1,naver_nosp2,
            kakao1,     kakao2,
            kakao_moment1,kakao_moment2,
            google1,    google2,
            meta1,       meta2,
        ]:DatshBoardStatResType[][] = await Promise.all([
            loadStatResportNaver(data.magId, week1.startDate, week1.endDate, userContext),
            loadStatResportNaver(data.magId, week2.startDate, week2.endDate, userContext),

            loadStatResportNaverGfa(data.magId, week1.startDate, week1.endDate, userContext),
            loadStatResportNaverGfa(data.magId, week2.startDate, week2.endDate, userContext),

            loadStatResportNaverNosp(data.magId, week1.startDate, week1.endDate, userContext),
            loadStatResportNaverNosp(data.magId, week2.startDate, week2.endDate, userContext),

            loadStatResportKakao(data.magId, week1.startDate, week1.endDate, userContext),
            loadStatResportKakao(data.magId, week2.startDate, week2.endDate, userContext),

            loadStatResportKakaoMoment(data.magId, week1.startDate, week1.endDate, userContext),
            loadStatResportKakaoMoment(data.magId, week2.startDate, week2.endDate, userContext),

            loadStatResportGoogle(data.magId, week1.startDate, week1.endDate, userContext),
            loadStatResportGoogle(data.magId, week2.startDate, week2.endDate, userContext),

            loadStatResportMeta(data.magId, week1.startDate, week1.endDate, userContext),
            loadStatResportMeta(data.magId, week2.startDate, week2.endDate, userContext),
        ]);

        // 하나의 광고주만 처리하기 때문에 client들만 배열로 바로 모읍니다.
        const statWeek1:DashBoardStatClientResType[] = [ 
            ...naver1       .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...naver_gfa1   .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...naver_nosp1  .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...kakao1       .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...kakao_moment1.reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...google1      .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...meta1        .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
        ];
        const statWeek2:DashBoardStatClientResType[] = [ 
            ...naver2       .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...naver_gfa2   .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...naver_nosp2  .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...kakao2       .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...kakao_moment2.reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...google2      .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
            ...meta2        .reduce((prev,v):DashBoardStatClientResType[]=>[...prev, ...v.clients], [] as DashBoardStatClientResType[]),
        ];

        let magData:DatshBoardStatTableType = mergeWeekData(data, statWeek1, statWeek2);

        const tableData:DatshBoardStatTableType[] = [magData].map((v1)=>({...v1, clients: v1.clients.map((v2)=>({
            ...v2,
            clientName: data?.clients?.find((v3)=>v3.clientId==v2.clientId)?.name,
        }))}));

        setUseStatData((prev)=>[...(prev.filter(v=>v.magId!==data?.magId)), ...tableData]);
        setLoading(false);
    }

    /** 조회 및 비교기간 변경 */
    const onChangeRangeDate = (week1?:DateRangeDataType, week2?:DateRangeDataType)=>{
        if(!week1 || !week2){ return; } //적용 안함
        setUseStatData([]);    //기간이 바뀌면 로딩된 실적데이터 모두 초기화
        setDateRange1(week1);
        setDateRange2(week2);
        loadStatResport(undefined, true, week1, week2);
    }

    useEffect(()=>{
        loadStatResport();
    // eslint-disable-next-line
    }, [useAccountGroup]);

    return <>
        <div className='mb-3 mt-6'>
            <div className='fw-bolder fs-3'>성과 대시보드</div>
        </div>
        
        {!userContext.userSelect.accountState?.list?.length && <ReportNone />}
        {userContext.userSelect.accountState?.list?.length && <>
            <div className='d-flex'>
                <Container className='justify-content-start p-0'>
                    <AccountGroupSelect value={useAccountGroup} onChange={setUseAccountGroup}/>
                </Container>
                <Container className='justify-content-end d-flex text-right p-0 pt-2 pb-2 h-50px'>
                    <div className='text-gray-600 p-3 me-6'>조회 기간 : <CdateFormatter {...dateRange1}/> vs 비교 기간 : <CdateFormatter {...dateRange2}/></div>
                    <DashboardDateRange defaultValue={{week1:dateRange1, week2:dateRange2}} 
                        onChange={onChangeRangeDate} />
                </Container>
            </div>

            <div className='mb-6'>
                <ReportCard info={useAccountGroup} value={useStatData.find((v2)=>v2.magId===useAccountGroup?.magId)} loading={loading} week1={dateRange1} week2={dateRange2} />
            </div>

            <ReportTable info={useAccountGroup} value={useStatData.find((v2)=>v2.magId===useAccountGroup?.magId)} loading={loading} />
        </>}
    </>
}
export default ReportDashboard;

/** 날짜 포맷 */
const CdateFormatter:React.FC<{startDate:Date, endDate:Date}> = (props)=><>{props.startDate.format('yyyy.MM.dd')}~{props.endDate.format('yyyy.MM.dd')}</>
