/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Config from '../../../../../../app/common/conf/Config';
import UserSelect from '../../../../../../app/common/helpers/UserSelect';
import Code from '../../../../../../app/modules/code/Code';
import ToggleSwitch from '../../../../../../app/modules/toggle/ToggleSwitch';
import { SidebarMenuItem } from '../SidebarMenuItem';
import { SidebarMenuItemWithSub } from '../SidebarMenuItemWithSub';
import SidebarMenuMainGoogleAdsTree from './SidebarMenuMainGoogleAdsTree';

const SidebarMenuMainGoogleAds = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [treeMode, setTreeMode] = useState<boolean>(false);
    const setMenuMode = (value:boolean)=>{
        setTreeMode(value);
        UserSelect.sidebar.set(value ? Code.base.sidebarMode.TREE.value : Code.base.sidebarMode.ALL.value);

        //현재 페이지가 메인 또는 대시보드페이지가 아닌 경우
        if(!['/', '/dashboard'].includes(location.pathname)){
            navigate('/');
        }
    }

    // const isDevelMode = ['devel', 'local'].includes(Config.project.mode);
    // const isLocalMode = ['local'].includes(Config.project.mode);

    useEffect(()=>{
        if(Config.app.TreeMode.enable){
            const pathTreeMode:boolean = window.document.location.pathname.indexOf("/pages/google/tree/") !== -1;    //URL로 바로 진입 시에도 TreeMode 표시하도록 함
            const selTreeMode:boolean = UserSelect.sidebar.get()!==Code.base.sidebarMode.ALL.value;
            const treeMode:string = (pathTreeMode || selTreeMode) ? Code.base.sidebarMode.TREE.value : Code.base.sidebarMode.ALL.value;
            UserSelect.sidebar.set(treeMode);
            setTreeMode(treeMode===Code.base.sidebarMode.TREE.value);
        }
    // eslint-disable-next-line
    }, []);

    return <>
        <div className='menu-item'>
            <div className='menu-content pt-3 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Google Ads</span>
                {Config.app.TreeMode.enable && 
                    <ToggleSwitch
                        className='w-80px'
                        style={{float:'right'}}
                        data-yes={Code.base.sidebarMode.TREE.label}
                        data-no={Code.base.sidebarMode.ALL.label}
                        onChange={(e)=>setMenuMode(e.target.checked)}
                        checked={treeMode}
                    />
                }
            </div>
        </div>
        
        {/* <SidebarMenuItem to='/pages/google/history' icon='/media/icons/duotune/abstract/abs027.svg' title='관리이력 & 대량요청' /> */}
        {/* <SidebarMenuItem to='/pages/google/downloadlist' icon='/media/icons/duotune/files/fil017.svg' title='다운로드' /> */}

        {treeMode && <>
            <SidebarMenuMainGoogleAdsTree />
        </>}
        {!treeMode && <>
            <SidebarMenuItemWithSub to='/pages/google' icon='/media/icons/duotune/general/gen022.svg' title='Google Ads'>
                <SidebarMenuItem to='/pages/google/all/campaign' title='캠페인' hasBullet={true} />
                <SidebarMenuItem to='/pages/google/all/adgroup' title='광고그룹' hasBullet={true} />
                <SidebarMenuItem to='/pages/google/all/ad' title='광고소재' hasBullet={true} />
            </SidebarMenuItemWithSub>
        </>}
    </>
}

export default SidebarMenuMainGoogleAds;
