import { StatReportInfoType, StatReportResType } from "../../../common/services/models/gfa/StatReportType";
import GfaCode from "../../../modules/code/GfaCode";
import { ModifyViewerType } from "../../modules/modify/Modify";


export interface ConvertStatInfoViewDataType extends Omit<ModifyViewerType,"title">{
    title: string|React.ReactNode;
}

export const convertStatInfoViewData = (level?:string, data?:StatReportInfoType):ConvertStatInfoViewDataType[]|undefined=>{
    switch(level){

        case GfaCode.data.options.ExplorerLevel.campaign.value : {
            return [
                {title:'캠페인 ID', value:data?.campaignId},
            ];
        }
        case GfaCode.data.options.ExplorerLevel.adGroup.value : {
            return [
                {title:'광고그룹 ID', value:data?.adGroupId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
            ];
        }

        case GfaCode.data.options.ExplorerLevel.ad.value : {
            return [
                {title:'소재ID', value:data?.adId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adGroupId},
            ];
        }
    }
    return [];
}

export const ExplorerTitle:React.FC<{level?:string, data?:StatReportInfoType}> = (props)=>{
    switch(props.level){
        case GfaCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case GfaCode.data.options.ExplorerLevel.adGroup.value : return <>광고그룹명 : {props.data?.adGroupId}</>;
        case GfaCode.data.options.ExplorerLevel.ad.value : return <>소재명 : {props.data?.adName}</>;
    }
    return <>Unknown</>;
}
/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
export const getStatDateFormatter = (txt:string)=>{
    const tmp:string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if(isNaN(new Date(tmp).getTime())){ return txt; }
    return new Date(tmp).format('yyyy-MM-dd');
}


/** Stat 컬럼만 Sum 처리 합니다. */
export const getStatReportTotal = (data:StatReportResType[]):StatReportResType => {
    const total:StatReportResType = {
        key:'합계',
        impressions: 0,
        clicks: 0,
        cost: 0,
        videoView: 0,
        conversions: 0,
        conversionsValue: 0,
    
        ctr: 0,
        cvr: 0,
        vtr: 0,
        roas: 0,
        cpc: 0,
        cpm: 0,
        cpv: 0,
    };

    const entries = Object.entries(total);
    data.forEach((v)=>{
        const tmp:Map<string,number> = new Map(Object.entries(v));
        entries.forEach((v2)=>{
            if(v2[0]==='key'){return; }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return {...total, ...Object.fromEntries(entries)};
}

export const AppendStatReportTotal = (data:StatReportResType[]):StatReportResType[] => {
    const total:StatReportResType = getStatReportTotal(data);
    data.push(total);
    return data;
}
