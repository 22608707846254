import Code from "./Code";

export default class KakaoMomentCode extends Code{

    public static readonly data = {
        options:{

            /*
            실적Level
            biz-channels
            campaigns
            ad-groups
            ads
            ad-extensions
            keywords
            creatives // 카카오
            creative-links // 카카오
            */
            ExplorerLevel:{
                campaign: {value:'campaigns', label:'캠페인'},
                adGroup: {value:'ad-groups', label:'광고그룹'},
                ad:     {value:'ads', label:'소재'},
            },

            /** Adgroup 디멘전 타입 */
            Dimension: {
                device:         {value: 'device',           label: '디바이스'},
                placement:      {value: 'placement',        label: '게재지면'},
                creativeFormat: {value: 'creativeFormat',   label: '소재유형'},
                location:       {value: 'location',         label: '지역'},
            },

            /** 지표 선택용 */
            dimensionType: {
                default:        {value:"-",                 label:"기본"},
                device:         {value:"device",            label:"디바이스"},
                placement:      {value:"placement",         label:"게재지면"},
                creative_format:{value:"creative_format",   label:"소재유형"},
                location:       {value:"location",          label:"지역"},
            },
        },

        Assets:{
            /** 캠페인 유형 */
            campaignType: {
                TALK_BIZ_BOARD: {value:'TALK_BIZ_BOARD',    label:'카카오톡비즈보드'},
                DISPLAY:        {value:'DISPLAY',           label:'디스플레이'},
                TALK_CHANNEL:   {value:'TALK_CHANNEL',      label:'카카오톡 채널'},
                DAUM_SHOPPING:  {value:'DAUM_SHOPPING',     label:'다음쇼핑'},
                VIDEO:          {value:'VIDEO',             label:'동영상'},
                PERSONAL_MESSAGE:{value:'PERSONAL_MESSAGE', label:'개인화 메시지'},
                KAKAO_TV:       {value:'KAKAO_TV',          label:'카카오TV'},
            },

            /** 캠페인 목표 */
            objective: {
                REACH:      {value:'REACH',         label:'도달'},
                VIEW:       {value:'VIEW',          label:'조회'},
                CONVERSION: {value:'CONVERSION',    label:'전환'},
                VISITING:   {value:'VISITING',      label:'방문'},
            },

            /** 광고 목표 대상 */
            objectiveType: {
                CATALOG:      {value:'CATALOG',         label:'카탈로그'},
                TALK_CHANNEL: {value:'TALK_CHANNEL',    label:'카카오톡채널'},
                PIXEL_AND_SDK:{value:'PIXEL_AND_SDK',   label:'픽셀&SDK'},
            },

            /** 최적화 전환 유형 */
            objectiveDetailType: {
                PURCHASE:               {value:'PURCHASE',                  label:'구매'},
                APP_INSTALL:            {value:'APP_INSTALL',               label:'카탈로그앱 설치'},
                COMPLETE_REGISTRATION:  {value:'COMPLETE_REGISTRATION',     label:'회원가입'},
                SIGN_UP:                {value:'SIGN_UP',                   label:'서비스 신청'},
                CART:                   {value:'CART',                      label:'장바구니'},
                PARTICIPATION:          {value:'PARTICIPATION',             label:'잠재고객'},
                ADD_FRIEND:             {value:'ADD_FRIEND',                label:'채널 친구 추가'},
                SEND_MESSAGE:           {value:'SEND_MESSAGE',              label:'메시지 발송'},
                CATALOG_PRODUCT_PURCHASE:{value:'CATALOG_PRODUCT_PURCHASE', label:'카탈로그 상품 구매'},
            },

            /** 과금 방식 */
            pricingType: {
                CPV:    {value:'CPV',   label:'CPV'},
                CPM:    {value:'CPM',   label:'CPM'},
                CPT:    {value:'CPT',   label:'CPT'},
                CPMS:   {value:'CPMS',  label:'CPMS'},
                CPA:    {value:'CPA',   label:'CPA'},
                CPC:    {value:'CPC',   label:'CPC'},
            },

            /** 입찰 방식 */
            strategy: {
                AUTOBID:        {value:'AUTOBID',       label:'자동입찰'},
                MANUAL:         {value:'MANUAL',        label:'수동'},
                OPTIMIZATION:   {OPTIMIZATION:'CPT',    label:'목적 최적화'},
            },

            /** 광고그룹 유형 */
            adGroupType: {
                DIRECT_MESSAGE: {value:'DIRECT_MESSAGE',    label:'다이렉트 메시지'},
                DISPLAY:        {value:'DISPLAY',           label:'디스플레이'},
                MESSAGE:        {MESSAGE:'CPT',             label:'메시지'},
            },

            adType: {
                IMAGE_BANNER:               {value:'IMAGE_BANNER',              label:'이미지 배너'},
                VIDEO_NATIVE:               {value:'VIDEO_NATIVE',              label:'비디오 네이티브'},
                IMAGE_NATIVE:               {value:'IMAGE_NATIVE',              label:'이미지 네이티브'},
                IMAGE_BOX:                  {value:'IMAGE_BOX',                 label:'이미지 박스'},
                CATALOG_MANUAL:             {value:'CATALOG_MANUAL',            label:'이미지 카탈로그'},
                CATALOG_DYNAMIC:            {value:'CATALOG_DYNAMIC',           label:'다이나믹 카탈로그'},
                SERVICE_CONTENT:            {value:'SERVICE_CONTENT',           label:'콘텐츠'},
                BASIC_TEXT_MESSAGE:         {value:'BASIC_TEXT_MESSAGE',        label:'기본 텍스트형'},
                WIDE_LIST_MESSAGE:          {value:'WIDE_LIST_MESSAGE',         label:'와이드 리스트형'},
                WIDE_MESSAGE:               {value:'WIDE_MESSAGE',              label:'와이드 이미지'},
                CAROUSEL_COMMERCE_MESSAGE:  {value:'CAROUSEL_COMMERCE_MESSAGE', label:'캐러셀 커머스'},
                CAROUSEL_FEED_MESSAGE:      {value:'CAROUSEL_FEED_MESSAGE',     label:'캐러셀 피드'},
                PREMIUM_VIDEO_MESSAGE:      {value:'PREMIUM_VIDEO_MESSAGE',     label:'프리미엄 동영상'},
                RICH_NATIVE:                {value:'RICH_NATIVE',               label:'RICH_NATIVE'},
                VIDEO_INSTREAM:             {value:'VIDEO_INSTREAM',            label:'VIDEO_INSTREAM'},
            },

        }
    };

    
};

