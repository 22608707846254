import Code from "./Code";

export default class AdminCode extends Code{

    public static readonly data = {
        //DB코드 데이터
        type:{
            true : {value : 'true', label : '사용'},
            false : {value : 'false', label : '사용안함'},
            TRUE : {value : 'TRUE', label : '사용'},
            FALSE : {value : 'FALSE', label : '사용안함'},
            DISABLED : {value : 'DISABLED', label : '사용안함'},
            ENABLED : {value : 'ENABLED', label : '사용'},
            Y : {value : 'Y', label : 'ON'},
            N : {value : 'N', label : 'OFF'},
            status: {
                Waite : {value : 'CTS0001', label : '대기'},
                Active : {value : 'CTS0002', label : '진행중'},
                Success : {value : 'CTS0003', label : '성공'},
                Fail : {value : 'CTS0004', label : '실패'},
            }
        },

        Assets:{
            mediaType:{
                naver:          {value:'naver',         code:"LMT0001", label:'NAVER SA',      data:{color:'#03C75A'}},
                naver_gfa:      {value:'naver_gfa',     code:"LMT2011", label:'NAVER 성과형 DA',  data:{color:'#6B8E23'}},
                naver_nosp:     {value:'naver_nosp',    code:"LMT2012", label:'NAVER 보장형 DA',  data:{color:'#006400'}},
                kakao:          {value:'kakao',         code:"LMT0002", label:'kakao SA',       data:{color:'#F3DC00'}},
                kakao_moment:   {value:'kakao_moment',  code:"LMT2021", label:'kakao 모먼트',     data:{color:'#DAA520'}},
                google:         {value:'google',        code:"LMT2041", label:'Google Ads',     data:{color:'#4085F7'}},
                meta:           {value:'meta',          code:"LMT2031", label:'Meta',           data:{color:'#4766A9'}},
            },
            Config:{
                ON : {value : 'ON', label : '활성화'},
                OFF : {value : 'OFF', label : '비활성화'},
                DEL : {value : 'DEL', label : '삭제'},
            },
            AccountConfig: {
                ALL : {value : 'ALL', label : '전체'},
                ON : {value : 'ACTIVE', label : '활성'},
                LOCK : {value : 'LOCKED', label : '잠금'},
                OFF : {value : 'RESIGNED', label : '사용안함'},
            },
            AccountNonLocked: {
                true : {value : 'true', label : '활성'},
                false : {value : 'false', label : '잠김'},
            },
            UseYesNo: {
                YES : {value : 'YES', label : '사용'},
                NO : {value : 'NO', label : '사용안함'},
            },
            UseYN: {
                Y : {value : 'Y', label : '사용'},
                N : {value : 'N', label : '사용안함'},
            },
            UserUseYn: {
                ALL : {value : '', label : '전체'},
                Y : {value : 'Y', label : '사용'},
                D : {value : 'D', label : '사용안함'},
            },
            DeviceTypes:{
                MOBILE : {value : 'MOBILE', label : '모바일'},
                PC : {value : 'PC', label : 'PC'},
            },
            DeliveryMethod : { 
                ACCELERATED : {value : 'ACCELERATED', label : '기본'},
                STANDARD : {value : 'STANDARD', label : '균등배분 '},
            },
            EventStatus:{
                COLLECTION : {value : 'COLLECTION', label : '수집중'},
                PRE_COLLECTION : {value : 'PRE_COLLECTION', label : '수집전'},
            },
            Role:{
                VIEWER: {value: 'VIEWER', label: 'Viewer'},
                OWNER: {value: 'OWNER', label: 'Owner'},
            },
            UserRole:{
                Admin: {value: 'Admin', label: 'Admin'},
                User: {value: 'User', label: 'User'},
            },
            MemberType:{
                MASTER : {value : 'MASTER', label : '마스터 권한'},
                MEMBER : {value : 'MEMBER', label : '멤버 권한'},
            },
            Placement:{
                PREMIUM_LINK_SEARCH_KAKAO : {value : 'PREMIUM_LINK_SEARCH_KAKAO', label : '검색 매체 카카오'},
                PREMIUM_LINK_SEARCH_PARTNER : {value : 'PREMIUM_LINK_SEARCH_PARTNER', label : '검색 매체 파트너'},
                PREMIUM_LINK_CONTENT_KAKAO : {value : 'PREMIUM_LINK_CONTENT_KAKAO', label : '콘텐츠 매체 카카오'},
                PREMIUM_LINK_CONTENT_PARTNER : {value : 'PREMIUM_LINK_CONTENT_PARTNER', label : '콘텐츠 매체 파트너'},
            },
            ReviewStatus:{
                APPROVED : {value : 'APPROVED', label : '심사승인'},
                WAITING : {value : 'WAITING', label : '심사중'},
                REJECTED : {value : 'REJECTED', label : '심사보류'},
            },

            MetricsGroups:{
                Name : {value : 'Name', label : 'Description'},
                BASIC : {value : 'BASIC', label : '기본지표'},
                ADDITION : {value : 'ADDITION', label : '추가지표'},
                PIXEL_SDK_CONVERSION : {value : 'PIXEL_SDK_CONVERSION', label : '픽셀&SDK 전환 지표'},
            },
            HistoryType: {
                CONNECTION : {value : 'CONNECTION', label : '광고주(MAG) 연결'},
                USE_YN : {value : 'USE_YN', label : '사용 여부'},
            },

            ViewType:{
                AUTOMATIC_PERIOD: {value:'AUTOMATIC_PERIOD', label:'상시 게시'},
                STATIC_PERIOD: {value:'STATIC_PERIOD', label:'기간 설정'},
            }
        },
    };
};

