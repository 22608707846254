import { GoogleService, KakaoMomentService } from "../../../common/services";
import GfaService from "../../../common/services/gfa/GfaService";
import MetaService from "../../../common/services/meta/MetaService";
import { EstimateAccountGroupType } from "../../../common/services/models/admin/EstimateTypes";
import { DatshBoardStatQryType, DatshBoardStatResType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import { DashboardStatDataType as GfaDashboardStatDataType } from "../../../common/services/models/gfa/DashboardStatReportType";
import { StatReportResType as GoogleStatReportResType } from "../../../common/services/models/google/StatReportType";
import { StatReportResType as KakaoMomentStatReportResType } from "../../../common/services/models/kakaomoment/StatReportType";
import { StatReportResType as MetaStatReportResType } from "../../../common/services/models/meta/StatReportType";
import { DashboardStatDataType as NospDashboardStatDataType } from "../../../common/services/models/nosp/DashboardStatReportType";
import NospService from "../../../common/services/nosp/NospService";
import { UserContextModel } from "../../../contexts/UserContext";
import { mergeDashboardReportData, totalDashboardReportDataExtension } from "./ReportDashboardData";


export const loadStatResportNaverGfa = async (id:number, startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: [id.toString()],
    };
        
    // userContext.modalLoading.show();
    return await GfaService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<GfaDashboardStatDataType>[] = res.data.result;
        const tmp2 = tmp.map((v1)=>({
            accountGroupId: v1.accountGroupId,
            accountGroupName: v1.accountGroupName,
            roleAuth: v1.roleAuth,
            accountGroupUseYn: v1.accountGroupUseYn,
            bookmarkYn: v1.bookmarkYn,
            bookmarkOrder: v1.bookmarkOrder,
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressions: v3.impressions,
                    clicks: v3.clicks,
                    cost: v3.cost,
                    purchase: 0,
                    purchaseValue: 0,
                    videoView: v3.videoView,
                })) || [],
                total: undefined,
            })) || [],
        }));
        
        return totalDashboardReportDataExtension(tmp2);
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title:"NAVER 성과형 DA 성과데이터 조회 오류", body:`NAVER 성과형 DA 성과데이터 조회 중 오류가 발생했습니다. ${GfaService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}

export const loadStatResportNaverNosp = async (id:number, startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: [id.toString()],
    };
        
    // userContext.modalLoading.show();
    return await NospService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<NospDashboardStatDataType>[] = res.data.result;
        const tmp2 = tmp.map((v1)=>({
            accountGroupId: v1.accountGroupId,
            accountGroupName: v1.accountGroupName,
            roleAuth: v1.roleAuth,
            accountGroupUseYn: v1.accountGroupUseYn,
            bookmarkYn: v1.bookmarkYn,
            bookmarkOrder: v1.bookmarkOrder,
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressions: v3.impressions,
                    clicks: v3.clicks,
                    cost: v3.costMoney,
                    purchase: 0,
                    purchaseValue: 0,
                    videoView: v3.videoView,
                })) || [],
                total: undefined,
            })) || [],
        }));
        return totalDashboardReportDataExtension(tmp2);
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title: "NAVER 보장형 DA 성과데이터 조회 오류", body:`NAVER 보장형 DA 성과데이터 조회 중 오류가 발생했습니다. ${NospService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}
   
export const loadStatResportKakaoMoment= async (id:number, startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: [id.toString()],
    };
        
    // userContext.modalLoading.show();
    return await KakaoMomentService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<KakaoMomentStatReportResType>[] = res.data.result;
        const tmp2 = tmp.map((v1)=>({
            accountGroupId: v1.accountGroupId,
            accountGroupName: v1.accountGroupName,
            roleAuth: v1.roleAuth,
            accountGroupUseYn: v1.accountGroupUseYn,
            bookmarkYn: v1.bookmarkYn,
            bookmarkOrder: v1.bookmarkOrder,
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressions: v3.impressions,
                    clicks: v3.clicks,
                    cost: v3.cost,
                    purchase: v3.convPurchase7d,
                    purchaseValue: v3.convPurchaseP7d,
                    videoView: v3.videoView,
                })) || [],
                total: undefined,
            })) || [],
        }));
        return totalDashboardReportDataExtension(tmp2);
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title:"카카오모먼트 성과데이터 조회 오류", body:`카카오모먼트 성과데이터 조회 중 오류가 발생했습니다. ${KakaoMomentService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}

   
export const loadStatResportGoogle= async (id:number, startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: [id.toString()],
    };
        
    // userContext.modalLoading.show();
    return await GoogleService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<GoogleStatReportResType>[] = res.data.result;
        const tmp2 = tmp.map((v1)=>({
            accountGroupId: v1.accountGroupId,
            accountGroupName: v1.accountGroupName,
            roleAuth: v1.roleAuth,
            accountGroupUseYn: v1.accountGroupUseYn,
            bookmarkYn: v1.bookmarkYn,
            bookmarkOrder: v1.bookmarkOrder,
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressions: v3.impressions,
                    clicks: v3.clicks,
                    cost: v3.cost,
                    purchase: v3.purchase,
                    purchaseValue: 0,
                    videoView: v3.videoView,
                })) || [],
                total: undefined,
            })) || [],
        }));
        return totalDashboardReportDataExtension(tmp2);
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title:"구글애즈 성과데이터 조회 오류", body:`구글애즈 성과데이터 조회 중 오류가 발생했습니다. ${GoogleService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}
   
export const loadStatResportMeta= async (id:number, startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: [id.toString()],
    };
        
    // userContext.modalLoading.show();
    return await MetaService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<MetaStatReportResType>[] = res.data.result;
        const tmp2 = tmp.map((v1)=>({
            accountGroupId: v1.accountGroupId,
            accountGroupName: v1.accountGroupName,
            roleAuth: v1.roleAuth,
            accountGroupUseYn: v1.accountGroupUseYn,
            bookmarkYn: v1.bookmarkYn,
            bookmarkOrder: v1.bookmarkOrder,
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressions: v3.impressions,
                    clicks: v3.clicks,
                    cost: v3.cost,
                    purchase: v3.purchase,
                    purchaseValue: v3.purchaseValue,
                    videoView: v3.videoViewStart,
                })) || [],
                total: undefined,
            })) || [],
        }));
        return totalDashboardReportDataExtension(tmp2);
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title:"메타 성과데이터 조회 오류", body:`메타 성과데이터 조회 중 오류가 발생했습니다. ${MetaService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}

export const mergeWeekData = (accountGroup:EstimateAccountGroupType, week1:DatshBoardStatResType[], week2:DatshBoardStatResType[]):DatshBoardStatTableType=>{
    return {
        ...accountGroup,
        clients: accountGroup.clients.map(v1=>{
            const mag1 = week1.find(v2=>v2.accountGroupId===accountGroup.magId);
            const mag2 = week2.find(v2=>v2.accountGroupId===accountGroup.magId);
            return {
                ...v1,
                week1: mergeDashboardReportData( mag1?.clients.find(v22=>v22.clientId.toString()===v1.clientId.toString())?.stats || []),
                week2: mergeDashboardReportData( mag2?.clients.find(v22=>v22.clientId.toString()===v1.clientId.toString())?.stats || []),
            }
        })
    };
}

