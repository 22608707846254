
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/gfa/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { SortKeys } from '../../modules/table/Table';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        ctr: StatFormatter.divide(row?.clicks, row?.impressions)?.round(3),
        cvr: StatFormatter.divide(row?.cost, row?.conversions)?.round(0),
        vtr: StatFormatter.divide(row?.videoView, row?.impressions)?.round(3),
        roas: StatFormatter.divide(row?.conversionsValue * 100, row?.cost)?.round(3),
        cpc: StatFormatter.divide(row?.cost, row?.clicks)?.round(3),
        cpm: StatFormatter.divide(row?.cost * 1000, row?.impressions)?.round(3),
        cpv: StatFormatter.divide(row?.cost, row?.videoView)?.round(3),
    }
}



export const addVat = (value?:number):number => {
    return  Math.round((value || 0) * 1.1);
}
// 이동 예정
export class StatFormatter{
    static comma(value?:number):string{ return value?.round().addComma() || '0'; }
    static commaPer(value?:number):string{ if(value){ value *= 100; } return (value?.round() || 0).addComma()+'%'; }
    static round(value?:number):string{ return (value?.round(1) || 0).addComma(); }
    static roundPer(value?:number):string{ if(value){ value *= 100; } return (value?.round(1) || 0).addComma()+'%'; }

    /**
     * 인자값 나눗셈을 합니다. - undefined 처리
     * @param value 값
     * @param by 나누기
     * @param times 값에 곱 - 퍼센트 처리 시 100을 입력합니다.
     * @returns 
     */
    static divide(value?:number, by?:number, times?:number):number{
        if(value===undefined || !by){ return 0; }
        if(times){ value *= times; }
        return value/by;
    }

    
    static cpa(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.round(value);
    }
    
    static addVatComma(value?:number, row?:StatReportResType, index?:number):string{
        return addVat(value)?.addComma();
    }
}

const headerStyle:React.CSSProperties = {};//{backgroundColor:'rgba( var(--bs-warning-rgb), 0.1)'};
const colStyle:React.CSSProperties ={minWidth:'100px', textAlign: 'right'};
export const defaultProps:HeaderColumn = {
    accessor:'',
    colGroup:'성과 Data',
    style:colStyle,
    headerStyle:headerStyle,
    useSort:false,
    formatter: StatFormatter.comma,
    defaultSortDirection:SortKeys.Desc, //기본 정렬 내림차순 
}

export const GfaOxfordColumns:HeaderColumn[] = [
    { ...defaultProps, accessor: StatReportResKeys.impressions,     header: '노출수',       unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.clicks,          header: '클릭수',       unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.videoView,       header: '총 재생',      unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.cost,            header: '총 비용',      unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.conversions,     header: '결과',         unit:'회', },

    { ...defaultProps, accessor: StatReportResKeys.cvr,             header: '결과당 비용',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.ctr,             header: '클릭률(CTR)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.vtr,             header: '조회율(VTR)',   unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.cpc,             header: '클릭당 평균 비용(CPC)',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpm,             header: '1천당 비용(CPM)',   unit:'원', },
    { ...defaultProps, accessor: StatReportResKeys.cpv,             header: '조회당 평균 비용(CPV)',   unit:'원', },
    
];

const localVatFormatter = (value: number, row: StatReportResType)=>StatFormatter.addVatComma(value,row) || 0;

/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalGfaOxfordColumns:HeaderColumn[] = [
    ...GfaOxfordColumns.map((v):HeaderColumn=>{
        return v.accessor===StatReportResKeys.cost
        ? {...v, filter:{type:FilterType.number, options:CompareSelectOption, formatter:localVatFormatter,}, useSort:true}  //비용 부가세 처리
        : {...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}
    })
];

