import axios, { AxiosRequestConfig } from 'axios';
import { KakaoMomentServer } from '../../axios';
import UserSelect from '../../helpers/UserSelect';
import Utils from '../../utils/Utils';
import { ExplorerDetailReqType, ExplorerPostReqType, StatReportResType, StatReportResultType } from '../models/kakaomoment/StatReportType';
import { MediaResponseType } from '../models/ResponseTypes';
import { Service } from '../Service';

import { ExplorerReqType, ExplorerResResultType } from '../models/kakaomoment/ExplorerTypes';
import AdGroupHelper from './AdGroupHelper';
import AdHelper from './AdHelper';
import CampaignHelper from './CampaignHelper';
import DashboardHelper from './DashboardHelper';
import DownloadHelper from './DownloadHelper';

/** NAVER NOSP 서비스 */
export default class KakaoMomentService extends Service{
    public static common = class{
        public static getDateRange(range?:{start:Date, end:Date}):{start:Date, end:Date}{
            const dateRange = UserSelect.dateRange.get();
            const start = (range?.start || new Date(dateRange?.startDate || new Date().addDays(-15)));
            const end = (range?.end || new Date(dateRange?.endDate || new Date().addDays(-1)));
            return  {start, end};
        }
    }

    public static explorer<T=any>(level:string, search?:string, from?:number, size?:number){
        const dateRange = UserSelect.dateRange.get();
        // const start = new Date().addDays(-15).format('yyyyMMdd');
        // const end = new Date().addDays(-1).format('yyyyMMdd');
        const start = new Date(dateRange?.startDate || new Date().addDays(-15)).format('yyyyMMdd');
        const end = new Date(dateRange?.endDate || new Date().addDays(-1)).format('yyyyMMdd');
        let dimension:string|undefined = undefined;
        let searchDimensionWord:string|undefined = undefined;

        // Dimension 조건 확인
        if(search && search.indexOf('-') !== -1){
            const token:string[] = search?.split("-");
            search = token[1];
            dimension = token[0];
            searchDimensionWord = token[2];
        }

        const params:ExplorerReqType = {
            // startDate: dateRange?.startDate?.replaceAll("-", "") || now,   // "20230501",
            // endDate: dateRange?.endDate?.replaceAll("-", "") || now,      //"20230530",
            startDate: start,
            endDate: end,
            searchWord: search || '',
            from: from || 0,
            size: size || 20,
            dimension,
            searchDimensionWord,
        };
        const config:AxiosRequestConfig = { params:params, headers:{useAccount:true} };
        const path = `/explorer/${level}`;
        return Utils.apiRequestValidation({}) || KakaoMomentServer.get<MediaResponseType<ExplorerResResultType<T>>>(path, config);
    }

    public static performance(level:string, id:string, range?:{start:Date, end:Date}){
        const dateRange = this.common.getDateRange(range);
        const start = dateRange.start.format('yyyyMMdd');
        const end = dateRange.end.format('yyyyMMdd');
        let dimension:string|undefined = undefined;
        let searchDimensionWord:string|undefined = undefined;

        // Dimension 조건 확인
        if(id && id.indexOf('-') !== -1){
            const token:string[] = id?.split("-");
            id = token[1];
            dimension = token[0];
            searchDimensionWord = token[2];
        }

        const params:ExplorerDetailReqType = {
            startDate: start,   // "20230501",
            endDate: end,      //"20230530",
            dimension,
            searchDimensionWord,
        };
        const config:AxiosRequestConfig = { params:params, headers:{useAccount:true} };
        const path = `/${level}/${id}/stats`;
        return Utils.apiRequestValidation({}) || KakaoMomentServer.get<MediaResponseType<StatReportResultType<StatReportResType>>>(path, config);
    }

    public static performanceDaily(level:string, ids:string[], range?:{start:Date, end:Date} ){
        const dateRange = this.common.getDateRange(range);
        const start = dateRange.start.format('yyyyMMdd');
        const end = dateRange.end.format('yyyyMMdd');

        const body:ExplorerPostReqType = {
            startDate: start,   // "20230501",
            endDate: end,      //"20230530",
            ids: ids,
        };
        
        const path = `/performance/${level}/daily`;
        return Utils.apiRequestValidation({}) || KakaoMomentServer.post<MediaResponseType<StatReportResultType<StatReportResType>>>(path, body, {headers:{useAccount:true} });
    }
    
    /**
     * S3URL을 이용하여 원하는 파일명으로 다운로드 합니다.
     * @param url S3 URL
     * @param filename : 다운로드시 생성될 파일명, 파일명을 지정하지 않는 경우 blob을 사용하지 않고 바로 새창열기를 통해 다운로드 시도합니다.
     */
    public static S3downloadReName(url:string, filename?:string){
        const server = axios.create();
        server.get(url).then((res)=>{
            //파일명을 지정하지 않는 경우 바로 다운로드
            if(!filename){
                window.open(url, '_blank');
            }else{
                // // 응답 헤더에서 response-content-disposition 값을 읽음
                // const contentDisposition = res.headers['response-content-disposition'];

                // // filename 속성의 값을 추출하기 위한 정규 표현식
                // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                
                // // 정규 표현식을 사용하여 filename 값을 추출
                // const matches = filenameRegex.exec(contentDisposition);
                
                // if (matches != null && matches[1]) {
                //     // filename 값이 존재할 경우 공백을 제거하고 출력
                //     const filename = matches[1].replace(/['"]/g, '');
                //     console.log('파일 이름:', filename);
                // } else {
                //     console.log('파일 이름을 찾을 수 없습니다.');
                // }

                const blob = new Blob([res.data]);
                // Blob을 파일로 다운로드
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename; // 새 파일 이름 설정
                link.click();
            }
        })
    }


    //////// Class /////////
    public static campaign      = CampaignHelper;
    public static adgroup       = AdGroupHelper;
    public static ad            = AdHelper;
    public static download      = DownloadHelper;
    public static dashboard     = DashboardHelper;
}