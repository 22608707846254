import { useEffect, useState } from "react";
import { GfaService } from "../../../common/services";
import { AdGroupResType } from "../../../common/services/models/gfa/AdGroupTypes";
import { AdResKeys, AdResType } from "../../../common/services/models/gfa/AdTypes";
import { CampaignResType } from "../../../common/services/models/gfa/CampaignTypes";
import { StatReportInfoType } from "../../../common/services/models/gfa/StatReportType";
import { MediaResResultType } from "../../../common/services/models/ResponseTypes";
import GfaCode from "../../../modules/code/GfaCode";
import { ModifyViewerType } from "../../modules/modify/Modify";

export const getMasterData = (level?:string, data?:any)=>{
    switch(level){

        case GfaCode.data.options.ExplorerLevel.campaign.value : {
            const tmp:StatReportInfoType|undefined = data;
            return tmp?.campaignId ? GfaService.campaign.get(tmp?.campaignId) : undefined;
        }
        case GfaCode.data.options.ExplorerLevel.adGroup.value : {
            const tmp:StatReportInfoType|undefined = data; 
            return tmp?.adGroupId ? GfaService.adgroup.get(tmp?.adGroupId) : undefined;
        }
        case GfaCode.data.options.ExplorerLevel.ad.value : {
            const tmp:StatReportInfoType|undefined = data;
            if(!tmp?.adId){ return undefined; }
            return GfaService.ad.getList<MediaResResultType<AdResType>>({filters:[{key:AdResKeys.adId, operation:'EQ', value:tmp?.adId}]})
                .then((res)=>{res.data.result = res.data.result.items?.[0]; return res; });  //개별 호출이 없음
        }
    }
    return undefined;
}


export const convertMasterViewData = (level?:string, data?:any):ModifyViewerType[]=>{
    switch(level){

        case GfaCode.data.options.ExplorerLevel.campaign.value : {
            const tmp:CampaignResType|undefined = data;
            return [
                {title:'캠페인ID', value:tmp?.campaignId},
            ];
        }
        case GfaCode.data.options.ExplorerLevel.adGroup.value : {
            const tmp:AdGroupResType|undefined = data;
            return [
                {title:'광고그룹ID', value:tmp?.adGroupId},
                {title:'캠페인명', value:tmp?.campaignName},
                {title:'캠페인ID', value:tmp?.campaignId},
            ];
        }

        case GfaCode.data.options.ExplorerLevel.ad.value : {
            const tmp:AdResType|undefined = data;
            return [
                {title:'소재ID', value:tmp?.adId},
                {title:'캠페인명', value:tmp?.campaignName},
                {title:'캠페인ID', value:tmp?.campaignId},
                {title:'광고그룹명', value:tmp?.adGroupName},
                {title:'광고그룹ID', value:tmp?.adGroupId},
            ];
        }
    }
    return [];
}



export const AdTitle:React.FC<{data?:any}> = (props)=>{
    const [data, setData] = useState<AdResType|undefined>();
    useEffect(()=>{
        getMasterData(GfaCode.data.options.ExplorerLevel.ad.value, props.data)?.then((res)=>{ setData(res.data.result);})
    }, [props.data]);
    
    let title:string|React.ReactNode|undefined = data?.adName;
    return <>{title}</>;
}

export const ExplorerTitle:React.FC<{level?:string, data?:StatReportInfoType}> = (props)=>{
    switch(props.level){
        case GfaCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case GfaCode.data.options.ExplorerLevel.adGroup.value : return <>광고그룹명 : {props.data?.adGroupName}</>;
        case GfaCode.data.options.ExplorerLevel.ad.value : return <>소재명 : {props.data?.adName}</>;
    }
    return <>Unknown</>;
}
