import clsx from "clsx";
import React, { CSSProperties, useState } from "react";
import { StatReportResType } from "../../../common/services/models/kakaomoment/StatReportType";
import { ChartDataType, ChartOptionType, StatReportChart } from "../../modules/charts";
import { OxfordColumns } from "../modules/OxfordColumns";

// const options:ChartOptionType[] = [
//     {label:'노출수',            value:'impressionCount',    unit:'회'},
//     {label:'클릭수',            value:'clickCount',         unit:'회'},
//     {label:'비용',              value:'adCost',             unit:'원'},
//     {label:'구매전환수',          value:'purchaseCount',        unit:'회'},
//     {label:'직접전환수',          value:'dcPurchaseCount',      unit:'회'},
//     {label:'간접전환수',          value:'icPurchaseCount',      unit:'회'},
//     {label:'회원가입수',          value:'subscriptionCount',    unit:'회'},
//     {label:'구매전환매출',        value:'purchaseValue',        unit:'원'},
//     {label:'직접전환매출액',       value:'dcPurchaseValue',     unit:'원'},
//     {label:'간접전환매출액',       value:'icPurchaseValue',     unit:'원'},
//     {label:'평균노출순위',         value:'averageAdRank',       unit:'위'},
//     {label:'클릭률(CTR)',        value:'clickThroughRate',      unit:'%'},
//     {label:'클릭당 평균 비용(CPC)', value:'costPerClick',       unit:'원'},
//     {label:'전환율(CVR)',         value:'conversionRate',   unit:'%'},
//     {label:'전환당 비용(CPA)',    value:'costPerAction',        unit:'원'},
//     {label:'회원가입당 비용',      value:'costPerSubscription', unit:'원'},
//     {label:'광고수익률(ROAS)',    value:'returnOnAdSpend',      unit:'%'},
// ];
const options:ChartOptionType[] = OxfordColumns.map((v)=>({
    label: v.header ? `${v.group ? `${v.group.header} - `:''}${v.header?.toString()}` : '-',
    value: v.accessor || '',
    unit: v.unit,
}));
interface ExplorerChartProps{
    className?: string;
    style?: CSSProperties;
    data: StatReportResType[];
}

const ExplorerChart:React.FC<ExplorerChartProps> = (props) => {
    const [value1, setValue1] = useState<ChartOptionType>(options[0]);
    const [value2, setValue2] = useState<ChartOptionType>(options[1]);

    return <StatReportChart 
        options={options}
        style={{height:'20em', ...props.style}}
        className={clsx('border mt-3', props.className)}
        barChart={{
            value: value1,
            data: props.data.map((v,index):ChartDataType=>[getTime(v.key), getColumn(v, value1.value) || 0]),   // 선이 이어지게하는 경우, '0' | 아에 빼는 경우 null
            onChange:(value)=>{setValue1(value);},
        }}
        lineChart={{
            value: value2,
            data: props.data.map((v,index):ChartDataType=>[getTime(v.key), getColumn(v, value2.value) || 0]),   // 선이 이어지게하는 경우, '0' | 아에 빼는 경우 null
            onChange:(value)=>{setValue2(value);},
        }}
    />
}

const getColumn = (value:StatReportResType, key:string) => {
    return new Map(Object.entries(value)).get(key)
}

/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
const getTime = (txt:string)=>{
    return new Date(txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')).getTime();
}

export default ExplorerChart;