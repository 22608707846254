import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import { ColumnsGroup } from '../../../modules/code/Config';
import ConfirmAlert from '../../../modules/message/confirm/ConfirmAlert';
import AdploButton from '../../components/buttons/AdploButton';
import AdPloSearch from '../../components/input/AdPloSearch';
import DragAndDropList from '../dragDrop/DragAndDropList';
import { ButtonDropDown } from '../dropdown/ButtonDropDown';
import { Checkbox } from '../form';
import style_colum from './table.column.module.scss';
import { HeaderColumn } from './TableType';
import TableUtils from './Utils';

export interface ColumnListProps {
    columns: HeaderColumn[];
    columnOnChange: (data:HeaderColumn[])=>void;
    className?: string;
    style?: CSSProperties;
}

export interface ColumnCustomType{
    key: string;
    checked: boolean;
    order: number;
}

function ColumnList(props:ColumnListProps) {
    const [search, setSearch] = useState<string>();
    const [search2, setSearch2] = useState<string>();
    const [columnConfig, setColumnConfig] = useState<ColumnCustomType[]>([]);
    const [confirmShow, setConfirmShow] = useState<boolean>(false);
    const columnData:HeaderColumn[][] = getColumns(props.columns);

    //최초 로딩 - 설정정보 초기화 또는 스토리지 반영
    useEffect(()=>{
        const data = TableUtils.storage.get(props.columns);
        setColumnConfig(data);
        onChange(data);
    },[props.columns]);

    const limited = columnConfig.filter((v)=>v.checked).length <= 1;  //마지막 하나는 남김
    const columnSort = (value:HeaderColumn[])=>{
        const tmp = props.columns.filter(v=>v.isFixed).map((v,index)=>({key:v.key || v.accessor, checked:true, order:index}));
        const length1:number = tmp.length;
        value.forEach((v,index)=>{
            tmp.push({key:v.key || v.accessor, checked:true, order:length1 + index});
        });

        const length2:number = tmp.length;
        props.columns.filter((v)=>!tmp.find(v2=>v2.key===(v.key || v.accessor)))
        .forEach((v,index)=>{
            tmp.push({key:v.key || v.accessor, checked:false, order:length2 + index});
        });
        onChange(tmp);
    }

    //컬럼선택 변경
    const columnOnCheckChange = (name:string|null, checked:boolean) => {
        const tmp = columnConfig.find(v=>v.key===name);
        if(tmp){ tmp.checked = checked; }
        onChange(columnConfig);
    }

    //컬럼 정보 반환
    const onChange = (data:ColumnCustomType[])=>{
        setColumnConfig(data);
        TableUtils.storage.set(props.columns, data);
        const tmp = props.columns.filter((v)=>v.isFixed);
        const tmp2 = data.filter((v)=>v.checked )
            .filter((v)=>!tmp.find(v2=>v.key===(v2.key || v2.accessor)))
            .map((v)=>props.columns.find((v2)=>v.key===(v2.key || v2.accessor)))
            .filter(v=>v) as HeaderColumn[];
        tmp2.forEach((v)=>tmp.push(v));
        // console.log(tmp);
        props.columnOnChange(tmp);
    }

    const reset = ()=>{
        const tmp = props.columns.map((v,index)=>({key:v.key || v.accessor, checked:v.isHide!==true, order:index}));
        onChange(tmp);
    }

    // 선택된 컬럼만 - 고정 포함
    const selectedColumns:HeaderColumn[] = columnConfig.filter((v)=>v.checked)
        .map((v)=>props.columns.find((v2)=>v.key===(v2.key || v2.accessor))) as HeaderColumn[];

    return (<ButtonDropDown
        className={clsx('d-inline', props.className)}
        title={<><i className="bi bi-layout-three-columns me-1" />Columns</>}
        style={props.style}
        useCaret={true}
    >
        <div className='p-4'>
            <div className=' pb-3 fw-bold border-bottom border-secondary'>Columns</div>
            <div className="d-flex">
                <div className='p-3'>
                    <div className='fw-bold p-2'>All Columns</div>
                    <AdPloSearch placeholder='Search column' onChange={(v)=>{ setSearch(v.target.value) } }/>
                    <div className='mt-3 scroll-y' style={{maxHeight:'400px'}}>
                        {
                            columnData?.map((groups, i) => (<div key={i} className='p-0' style={{minWidth:'15em'}}>
                                <div className="fw-bold pt-2 ps-3">
                                    {ColumnsGroup[i] ? ColumnsGroup[i] : 'Group ' + (i+1)}
                                </div>
                                <ul className='m-0 p-0 pt-1 ps-2' style={{listStyle:'none'}}>{
                                    groups.filter((v)=>(v.header?.toString().indexOf(search || "") || 0)> -1).map((item, k) => {
                                        const checked = columnConfig.find((v)=>v.key===(item.key || item.accessor))?.checked;
                                        return(<li key={`${i}_${k}`} className={clsx('p-1', style_colum.columnlist_item)} >
                                            <Checkbox checked={item.isFixed || checked} disabled={item.isFixed || (checked && limited) || false}
                                                onChange={(e)=>columnOnCheckChange(item.key || item.accessor, e.target.checked)}
                                                value={item.key || item.accessor} size='sm'
                                            >
                                                <span className='d-inline-block text-truncate'>{item.group && <>{item.group.header} - </>}{item.header}</span>
                                            </Checkbox>
                                        </li>)
                                    })
                                }</ul>
                            </div>)
                        )}
                    </div>
                </div>

                <div className='p-3 border-start border-secondary'>
                    <div className='fw-bold p-2'>Selected Columns <span className='text-primary'>{selectedColumns.length.addComma()}</span></div>
                    <AdPloSearch placeholder='Search column' onChange={(v)=>{ setSearch2(v.target.value) } }/>
                    <div className='mt-3 scroll-y' style={{maxHeight:'400px'}}>
                        {
                            columnData?.map((groups, i) => (<div key={`fixed-${i}`} className='p-0' style={{minWidth:'15em'}}>
                                {groups
                                    .filter((v)=> columnConfig.find((v2)=>v2.key===(v.key || v.accessor))?.checked)
                                    .filter((v)=>v.isFixed)
                                    .filter((v)=>(v.header?.toString().indexOf(search2 || "") || 0)> -1).map((item, k) => {
                                        return(<div key={`${i}_${k}`} className={clsx('px-2 pt-2 ps-7 my-2 border border-gray-300 rounded')} >
                                            <span className='d-inline-block text-truncate m-0'>{item.group && <>{item.group.header} - </>}{item.header}</span>
                                            <i className="bi bi-lock-fill" style={{float:"right",}} />
                                        </div>)
                                    })
                                }
                            </div>)
                        )}

                        <DragAndDropList<HeaderColumn>
                            onChange={columnSort}
                            options={
                                selectedColumns.filter((v)=>v && !v.isFixed)    //고정형 제외
                                    .filter((v)=>(v?.header?.toString().indexOf(search2 || "") || 0)> -1) as HeaderColumn[] // 검색조건
                            }
                            readOnly={(search2?.length || 0) > 0}
                            
                            formatter={(v)=><div className='d-flex p-2 pb-1 my-2 border border-gray-300 rounded text-nowrap'>
                                <div className="me-9">
                                    {(search2?.length || 0) > 0 && <i className="bi bi-lock-fill" style={{position:'absolute', fontSize:"0.83em", marginLeft:"-0.5em"}}/>}
                                    <i className="bi bi-grip-vertical" />
                                    {v.header}
                                </div>
                                <div className="cursor-pointer position-absolute" onClick={()=>{}} style={{right:'0.5em'}}>
                                    <i className="bi bi-x-lg fs-8" onClick={(e)=>columnOnCheckChange(v.key || v.accessor, false)}/>
                                </div>
                            </div>}
                        />

                    </div>
                </div>

            </div>
            <div className='border-top border-secondary pt-3'>
                <AdploButton className="btn-light btn-sm me-1" onClick={()=>setConfirmShow(true)}>기본값으로 초기화</AdploButton>
                <ConfirmAlert title="컬럼 설정값 초기화" state={[confirmShow, setConfirmShow]} ok={{onClick:()=>reset(), title:"초기화"}} cancel={{title:"취소"}}>
                    컬럼 설정값을 초기화 됩니다.<br/>
                    계속 진행하시겠습니까?
                </ConfirmAlert>
            </div>
        </div>
    </ButtonDropDown>);
}

const getColumns = (columns:HeaderColumn[]):HeaderColumn[][] => {
    let resData:HeaderColumn[][] = [];
    const groups:(string|undefined)[] = columns.map((v)=>v.colGroup).filter((v)=>v).unique();

    columns.forEach((v, index) => {
        const idx:number = v.colGroup ? groups.indexOf(v.colGroup)+1 : 0;
        if(resData[idx] === undefined) resData[idx] = [];
        resData[idx].push(v);
    })

    return resData;
}

export default ColumnList;