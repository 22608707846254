import React, { Dispatch, SetStateAction } from 'react';
import { OptionType } from '../../../../modules/types/Types';
import { RequestFilterType, RequestOrderType } from '../../../common/services/models/RequestTypes';
import { EqInExSelectOptions } from '../../../modules/code/Options';
import Formatter from '../Formatter';

/**
 * Custom Table용 컬럼 설정 타입
 */
export interface HeaderColumn {
    /** 컬럼구분용 고유 키 */
    key?: string;

    /** 데이터 접근용 name */
    accessor: string;

    /** Table 헤더 구성 컨텐츠 */
    header?: string | React.ReactNode;

    /** 헤더 컬럼 병합 설정 */
    colSpan?: number;

    // id?: string | number;

    /** Column Width */
    minWidth?: string | number;

    /** Column Width */
    maxWidth?: string | number;

    /** Column Width */
    width?: string | number;

    // isDetail?: boolean;
    // handleDetail?: string;

    /** body cell  */
    align?: string;
    /**
     * Body cell 출력 포맷 지정
     * @param value 해당Cell 값
     * @param row  해당 Row 데이터
     * @param index Row index
     * @returns 
     */
    formatter?: FormatterPropType;

    /** value값을 변형하는 역할을 수행합니다. formatter 보다 우선하여 처리됨  */
    preProcessing?:(value?:any, row?:any, index?:number)=>any;

    /** 초기 해당 Column표시 여부 */
    isHide?: boolean;

    /** Header Group 설정 */
    group?:HeaderGroup;

    // links?: string[];

    /** Filter 구성 옵션 */
    filter?:FilterPropType;

    /** Body Cell 클래스 지정 */
    className?:string;

    /** 헤더 설정용 */
    headerClassName?:string;

    /** Body Cell 스타일 지정 */
    style?:React.CSSProperties;

    headerStyle?:React.CSSProperties;

    colGroup?:string;
    
    /** 고정여부 설정 */
    isFixed?:boolean;
    
    /** 정렬 사용여부 */
    useSort?:boolean;
    sort?:{
        /** 프론트 정렬처리 시 비교 값 전처리 */
        formatter?: (value:any, row:any, index?:number, sort?:RequestOrderType)=>string|number;
        /** 정렬 비교 */
        compare?: (a:any, b:any, sort?:RequestOrderType)=>number;
    }

    /** Table에서 사용안함, Stat차트용으로 사용됨 */
    unit?:string;

    /** 기본 정렬 값 */
    defaultSortDirection?: string;

    /** 컬럼 노출여부 제어 - 메이페이지에서 키형태로 제어할 수 있도록 옵션 제공 */
    viewFilter?: (v:(string|undefined)[])=>boolean;
}

export type FormatterPropType = (value:any, row?:any, index?:number)=>React.ReactNode|string|undefined;

export interface FilterPropType{
    key?:string;
    type:FilterType;
    options?:FilterOptionType[];
    numberOnly?:boolean;
    operation?:string;
    /** 프론트 필터처리 시 비교 값 전처리 */
    formatter?: (value:any, row:any, index?:number)=>string[]|string|number;
}

export interface HeaderGroup{
    header?:string;
    key: string;
    align?: string;
    colspan?:number;    //내부계산에서만 사용함
}

export interface TablePropsType<T=any>{
    columns: HeaderColumn[];
    /** 컬럼 표시여부를 제어 합니다. columns 목록은 제어 안함 */
    columnViewOption?: (string|undefined)[];
    showColumns?:boolean;
    data: T[];
    setFilterValues?: Dispatch<SetStateAction<RequestFilterType[]>>|((v:RequestFilterType[])=>void);
    filterValues?: RequestFilterType[];
    defaultFilter?: RequestFilterType[];
    setCheckIds?: Dispatch<SetStateAction<T[]>>|((v:T[])=>void);
    checkIds?:T[];
    /** 검색버튼과 동일 - 기본값 false */
    showRefresh?:boolean;
    showSearch?:boolean;
    handleSearch?: (page?:number, size?:number, filters?:RequestFilterType[], search?:string)=>void;    //선언여부에 따라 '검색' 버튼 활성화
    /** 강제 데이터 재로딩 */
    dataReload?: ()=>void;
    /** 페이징을 사용하지 않고 더보기 버튼 표시 */
    handleMoreLoad? : (page?:number, size?:number, filters?:RequestFilterType[], search?:string)=>void; 
    /** 기본값:false, 검색어 입력창 표시 및 검색어 처리, filterable=false 일 때만 사용 가능 */
    showSearchBox?: boolean;
    searchBoxPlaceholder?:string;
    searchValue?: string;
    handleDownload?: ()=>void;   //선언여부에 따라 '다운로드' 버튼 활성화
    showDeviceType?: boolean;
    onChangeDeviceType?: (value:string[])=>void;
    handleSort?: (value:RequestOrderType)=>void;
    sorts?:RequestOrderType[];
    onChangePage?: (info:PageInfoType)=>void;
    totalData?:number;
    chekcedId?:string;
    pageNum?:number;
    pageSize?:number;
    pageSizeOptions?: OptionType[];
    pageable?:boolean;
    filterable?:boolean;    //필터 사용여부
    checkbox?:boolean;      //체크박스 사용여부
    // sortable?:boolean;     //정렬 사용여부
    className?:string;
    /** 왼쪽에 버튼을 배치합니다. */
    customButton?:React.ReactNode;
    /** 오른쪽 검색버튼 앞쪽에 버튼 배치 */
    appendButton?:React.ReactNode;
    /** 다운로드 버튼 앞, 실적 지표 선택 옵션버튼 위치에 추가 */
    optionButton?:React.ReactNode;

    staticData?:any[];
    /** 좌우 스크롤에 대한 툴바 고정여부 */
    fiexedToolbar?:boolean;

    /** checkbox 비활성화 대상 키목록 */
    checkDisabled?:string[];

    /** 다운로드 버튼 비활성화 */
    downloadDisabled?:boolean;

    /** 데이터가 없는 경우 표시될 문구 */
    dataNoneNotice?: string|React.ReactNode;
}

export interface CheckedListType<T=any> {
    id: string;
    data: T;
}

export interface TableFilterPropsType {
    id:string;
    type:FilterType;
    options?:SelectType[];
    onChange:(e:RequestFilterType)=>void;
    operation?:string;
    value?:string;
    numberOnly?:boolean;
}


export interface FilterOptionType {
    label:string; 
    value:string;
    checked?:boolean;
    /** 선택목록에 표시여부 */
    isView?:boolean;
}

export interface PageInfoType{
    page: number;
    size: number;
}

export interface AdminSearchType {
    search?: string;
    accountStatus?: string;
    accountNonLocked?: string;
    useYn?: string;
    keyword?: string;
}

export interface SelectType {
    value: string;
    label: string;
}

export interface FilterViewType{
    key?:string|React.ReactNode;
    operation?:string;
    value?:string[];
}

export enum FilterType{
    select="select",
    mSelect="mSelect",
    arraySelect="arraySelect",
    list="list",
    date="date",
    text="text",
    number="number",
    none="none",
}


/** 로컬 URL 타입 컬럽 기본속성 */
export const LocalHiperLinkColumnConfig:HeaderColumn = {
    header: '링크', accessor:'url' , style:{textAlign: 'left', minWidth:'250px'},
    filter:{type:FilterType.list, options:EqInExSelectOptions, formatter:(v)=>v,},  
    formatter: Formatter.UrlHiperLinkFormatter,
    sort:{formatter:(v)=>v || '', },
}
