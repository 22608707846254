import { AdGroupResType } from "../../../common/services/models/nosp/AdGroupTypes";
import { AdResType } from "../../../common/services/models/nosp/AdTypes";
import { CampaignResType } from "../../../common/services/models/nosp/CampaignTypes";
import { CreativeResType } from "../../../common/services/models/nosp/CreativeTypes";
import { KeywordResType } from "../../../common/services/models/nosp/KeywordTypes";
import { StatReportInfoType, StatReportResType } from "../../../common/services/models/nosp/StatReportType";
import NospCode from "../../../modules/code/NospCode";
import { ModifyViewerType } from "../../modules/modify/Modify";
import { getDimensionInfo } from "./ExplorerListDetail";


export interface ConvertStatInfoViewDataType extends Omit<ModifyViewerType,"title">{
    title: string|React.ReactNode;
}

export const convertStatInfoViewData = (level?:string, data?:StatReportInfoType):ConvertStatInfoViewDataType[]|undefined=>{
    const dimensionInfo = getDimensionInfo(undefined, data);   //디멘전정보 추출
    switch(level){
        case NospCode.data.options.ExplorerLevel.campaign.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'캠페인명', value:data?.campaignName}); }
            return [
                ...tmp,
                {title:'캠페인 ID', value:data?.campaignId},
            ];
        }
        case NospCode.data.options.ExplorerLevel.ad.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'광고라인 ID', value:data?.adGroupId}); }
            return [
                ...tmp,
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
            ];
        }

        case NospCode.data.options.ExplorerLevel.creative.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'광고소재명', value:data?.creativeId}); }
            return [
                ...tmp,
                {title:'광고소재 ID', value:data?.adId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'광고라인 ID', value:data?.adId},
            ];
        }
        case NospCode.data.options.ExplorerLevel.ad.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'집행소재 ID', value:data?.adId}); }
            return [
                ...tmp,
                {title:'광고소재 ID', value:data?.adId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'광고라인 ID', value:data?.adGroupId},
            ];
        }
        case NospCode.data.options.ExplorerLevel.keyword.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'키워드명', value:data?.keywordName}); }
            return [
                ...tmp,
                {title:'키워드 ID', value:data?.keywordId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'광고라인 ID', value:data?.adGroupId},
                {title:'광고소재 ID', value:data?.creativeId},
            ];
        }
       
    }
    return [];
}


export const ExplorerTitle:React.FC<{level?:string, search?:string, data?:StatReportInfoType}> = (props)=>{
    const dimensionInfo = getDimensionInfo(props.data?.campaignId, props.data, undefined, props.search);
    if(dimensionInfo.dimentsion){
        return <>{NospCode.getLabel([
            NospCode.data.options.campaignDimensionType,
            NospCode.data.options.keywordDimensionType
        ], dimensionInfo.dimentsion)} : {dimensionInfo.dimentsionWord}</>; 
    }
    switch(props.level){
        case NospCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case NospCode.data.options.ExplorerLevel.adgroup.value : return <>광고그룹 ID : {props.data?.adGroupId}</>;
        case NospCode.data.options.ExplorerLevel.creative.value : return <>광고소재 ID : {props.data?.creativeId}</>;
        case NospCode.data.options.ExplorerLevel.ad.value : return <>집행소재 ID : {props.data?.adId}</>;
        case NospCode.data.options.ExplorerLevel.keyword.value : return <>키워드 : {props.data?.keywordName}</>;
    }
    return <>Unknown</>;
}

/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
export const getStatDateFormatter = (txt:string)=>{
    const tmp:string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if(isNaN(new Date(tmp).getTime())){ return txt; }
    return new Date(tmp).format('yyyy-MM-dd');
}

/** Stat 컬럼만 Sum 처리 합니다. */
export const getStatReportTotal = (data:StatReportResType[]):StatReportResType => {
    const total:StatReportResType = {
        key:'합계',
        costMoney: 0,
        impressions: 0,
        clicks:0,
        landingClicks: 0,
        interactionsClicks: 0,
        videoPlay3s: 0,
        videoPlay10s: 0,
        videoView: 0,
        videoView25: 0,
        videoView50: 0,
        videoView75: 0,
        videoView100: 0,

        ctr: 0,
        vtr: 0,
        cpc: 0,
        cpm: 0,
        cpv: 0,
    };

    const entries = Object.entries(total);
    data.forEach((v)=>{
        const tmp:Map<string,number> = new Map(Object.entries(v));
        entries.forEach((v2)=>{
            if(v2[0]==='key'){return; }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return {...total, ...Object.fromEntries(entries)};
}

export const AppendStatReportTotal = (data:StatReportResType[]):StatReportResType[] => {
    const total:StatReportResType = getStatReportTotal(data);
    data.push(total);
    return data;
}


/** Explorer 키생성용 - campaign, adgroup*/
export const getExplorerValue = (value?: string, row?:AdGroupResType|CampaignResType|AdResType|CreativeResType|KeywordResType, dimensionType?:string):string|undefined=>{
    switch(dimensionType){
        case NospCode.data.options.campaignDimensionType.hour.value :       return `${dimensionType}-${value}-${(row as CampaignResType)?.hour}`;
        case NospCode.data.options.campaignDimensionType.placement.value :  return `${dimensionType}-${value}-${(row as CampaignResType)?.placement}`;
        case NospCode.data.options.keywordDimensionType.celement.value :    return `${dimensionType}-${value}-${(row as KeywordResType)?.celement}`;
    }
    return value;
}

/** Stat 키생성용 - campaign, adgroup */
export const getStatValue = (value?: string, row?:AdGroupResType|CampaignResType|AdResType|CreativeResType|KeywordResType, dimensionType?:string):string|undefined=>{
    switch(dimensionType){
        case NospCode.data.options.campaignDimensionType.hour.value :       return `${value}|${(row as CampaignResType)?.hour}`;
        case NospCode.data.options.campaignDimensionType.placement.value :  return `${value}|${(row as CampaignResType)?.placement}`;
        case NospCode.data.options.keywordDimensionType.celement.value :    return `${value}|${(row as KeywordResType)?.celement}`;
    }

    return value;
}