/** Stat 기본 연산처리용 Utilites */
export class StatUtils{
    static comma(value?:number):string{ return value?.round().addComma() || '0'; }
    static commaPer(value?:number):string{ if(value){ value *= 100; } return (value?.round() || 0).addComma()+'%'; }
    static round(value?:number):string{ return (value?.round(1) || 0).addComma(); }
    static roundPer(value?:number):string{ if(value){ value *= 100; } return (value?.round(1) || 0).addComma()+'%'; }

    /**
     * 인자값 나눗셈을 합니다. - undefined 처리
     * @param value 값
     * @param by 나누기
     * @param times 값에 곱 - 퍼센트 처리 시 100을 입력합니다.
     * @returns 
     */
    static divide(value?:number, by?:number, times?:number):number{
        if(value===undefined || !by){ return 0; }
        if(times){ value *= times; }
        return value/by;
    }
    
    static addVat(value?:number):number{
        return  Math.round((value || 0) * 1.1);
    }
}
