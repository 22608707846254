import clsx from "clsx";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { OptionType } from "../../../../modules/types/Types";
import { EstimateAccountGroupType } from "../../../common/services/models/admin/EstimateTypes";
import { DashBoardStatTableClientResType, DashBoardStatTableClientType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import Badge from "../../../modules/badge/Badge";
import AdminCode from "../../../modules/code/AdminCode";
import Ellipsis from "../../../modules/layer/Ellipsis";
import { DateRangeDataType } from "../../modules/calendar/DateRangePicker";
import CustomBaseCard from "../../modules/card/CustomBaseCard";
import MediaTypeIcon, { getMediaTypeColor } from "../../modules/symbol/MediaTypeIcon";
import CircleChart, { CircleChartItemType } from "./CircleChart";
import DimensionSetting, { Dimension } from "./DimansionSetting";
import MediaTypeButton from "./MediaTypeButton";
import { defaultDashboradReportData, mergeDashboardReportData, statDashboardReportDataExtension } from "./ReportDashboardData";

export enum SelectTypeKey{
    none = 'none',
    media = 'media',
    client='client'
}

interface ReportCardProps{
    info?: EstimateAccountGroupType;
    value?:DatshBoardStatTableType;
    loading?:boolean;
    bookmarkRemove?:(id:number)=>void;
    week1: DateRangeDataType;
    week2: DateRangeDataType;
}

const ReportCard:React.FC<ReportCardProps> = (props)=>{
    const [selectValue, setSelectValue] = useState<string|undefined>();
    const [selectMediaType, setSelectMediaType] = useState<string|undefined>();
    const [selectClientId, setSelectClientId] = useState<string|undefined>();
    // const [selectType, setSelectType] = useState<string>(SelectTypeKey.none);
    const [chartData, setChartData] = useState<CircleChartItemType[]>([]);
    const [tableData, setTableData] = useState<DashBoardStatTableClientType>({week1:defaultDashboradReportData(), week2:defaultDashboradReportData()});
    const [selectIndex, setSelectIndex] = useState<number|undefined>();
    const [dimension, setDimension] = useState<string[]>([]);
    const deviceTypes:string[] = ["P","M"];

    useEffect(()=>{
        showAllType(props.value);
    // eslint-disable-next-line
    }, [props.value]);

    // 광고주선택 변경시 매체선택정보 초기화
    useEffect(()=>{
        mediaTypeChange("");
    }, [props.info]);


    /** 전체 표시 */
    const showAllType = (data?:DatshBoardStatTableType)=>{
        data = data || props.value;
        const tmp:CircleChartItemType[] = AdminCode.getOptions(AdminCode.data.Assets.mediaType)
        .map((v):CircleChartItemType=>({
            id: v.value,
            label: v.label,
            type: SelectTypeKey.media,
            value: data?.clients
                .filter((v1)=>v1.mediaType.toLocaleLowerCase() ===v.value || v1.mediaType ===v.code)
                .reduce((prev,v)=>prev + v.week1.cost, 0) || 0,
            color: v.data?.color,
        }))
        .sort((a,b)=>a.value > b.value ? -1 : 1)
        .filter((v)=>(data?.clients.filter((v1)=>v1.mediaType.toLocaleLowerCase()===v.id).length || 0)>0);
        setChartData(tmp);
        const clientData = getClientsData(data?.clients || []);
        setTableData(clientData);
    }

    /** 특정 매체 표시 */
    const showMediaType = (media:string)=>{
        //보라님이 요청 매체별 색상표
        // const map = new Map<string, string[]>(Object.entries({
        //     NAVER : ["#f3e9d2", "#c6dabf", "#88d498", "#1a936f", "#114b5f", "#052f5f", "#1b2a41",],
        //     KAKAO : ["#ffc15e", "#f7b05b", "#f7934c", "#cc5803", "#8f250c",],
        //     GOOGLE : ["#cfdee7", "#92b4f4", "#5e7ce2", "#4472ca", "#0a369d",],
        // }));

        setChartData(
            props.value?.clients.filter((v)=>v.mediaType.toLocaleLowerCase()===media.toLocaleLowerCase())
            .map((v)=>({
                ...v,
                value: v.week1,
            }))
            .sort((a,b)=>a.value.cost > b.value.cost ? -1 : 1)
            .map((v, index):CircleChartItemType=>{
                const colors:string[] = ["#FFBE0B","#FB5607","#FF006E","#8338EC","#3A86FF","#4CB050","#9E9E9E"];
                return {
                    ...v,
                    id : v.clientId.toString(),
                    label: v.clientName || "",
                    type: SelectTypeKey.client,
                    value: v.value.cost,
                    color: colors?.[index] || "",
                    mediaType: v.mediaType,
                }
            }) || []
        );
        
        const clientData = getClientsData(props.value?.clients.filter((v)=>v.mediaType.toLocaleLowerCase()===media.toLocaleLowerCase()) || []);
        setTableData(clientData);
    }

    /** 특정 자산 표시 */
    const showClientId = (id:string, mediaType?:string)=>{
        showMediaType(mediaType || "");
        const client:DashBoardStatTableClientResType|undefined = props.value?.clients.find((v)=>v.mediaType.toLocaleLowerCase()===mediaType?.toLocaleLowerCase() && v.clientId.toString()===id);
        setTableData({week1: client?.week1 || defaultDashboradReportData(), week2: client?.week2 || defaultDashboradReportData()});
    }

    const mediaTypeChange = (value:string, type?:string, mediaType?:string)=>{
        // 자산을 선택한 경우, 상위 매체 선택처리 필요
        // if(type===SelectTypeKey.client){
        //     const client = props.value?.clients.find((v)=>v.clientId.toString()===value);
        //     client && legendClick(client.mediaType, SelectTypeKey.media, mediaType);
        // }else{
            legendClick(value, type || SelectTypeKey.none, mediaType);
        // }
    }

    const legendClick = (id:string, type?:string, mediaType?:string)=>{
        setSelectValue(id);
        /** 매체선택 */
        switch(type){
            case SelectTypeKey.media : {
                // setSelectType(SelectTypeKey.media);
                setSelectMediaType(id);
                setSelectClientId(undefined);
                showMediaType(id);
                break;
            }
            
            /** 자산선턱 */
            case SelectTypeKey.client : {
                // setSelectType(SelectTypeKey.client);
                setSelectClientId(id);
                setSelectMediaType(mediaType?.toLocaleLowerCase());
                showClientId(id, mediaType?.toLocaleLowerCase());
                break;
            }
            
            /** 모두표시 */
            default :
                //setSelectType(SelectTypeKey.none);
                setSelectMediaType(undefined);
                setSelectClientId(undefined);
                showAllType();
        }
    }

    const mediaType:OptionType|undefined = AdminCode.getOptions(AdminCode.data.Assets.mediaType).find(v=>v.value===selectMediaType);

    return <div className="mb-5">
        <div className="row">
            <div className="col-5">
                <CustomBaseCard title="비용" style={{height:'100%'}}>
                    {props.loading && <div className="text-center h-200px pt-20"><Spinner className='mt-6' animation="border" variant={"secondary"} role='status' /></div>}
                    {!props.loading && <div className='row'>
                        <div className="d-flex">
                            <CircleChart data={chartData} title='총 비용'
                                selection={(v:number)=>{setSelectIndex(v); legendClick(chartData[v].id.toString(), chartData[v].type, chartData[v].mediaType)}}
                                selectionIndex={selectIndex}
                            /> 
                            <div className="align-content-center">
                                {mediaType && <div
                                    className="fw-bolder p-2 ps-1 fs-5 me-2 cursor-pointer bg-hover-light rounded" 
                                    onClick={()=>{legendClick('')}}
                                    style={{color:mediaType?.data?.color, marginTop:"-2em"}}
                                >
                                    <MediaTypeIcon code={mediaType?.code} label={mediaType?.label} viewLabel={true}/>
                                    <i className="bi bi-arrow-left fs-3" style={{float:'right', lineHeight:'140%'}} />
                                </div>}
                                <div className="align-items-center scroll p-0" style={{maxHeight:'250px'}}>
                                    {chartData.map((v,index)=><MediaLegendItem
                                        key={v.id}
                                        type={v.label}
                                        color={v.color}
                                        value={(v.value/(chartData.reduce((prev,v2)=>prev+(v2.value || 0),0) || 1)*100)}
                                        onClick={()=>{setSelectIndex(index); legendClick(v.id, v.type, v.mediaType);}}
                                        checked={v.id===selectClientId}
                                    />)}
                                </div>
                            </div>
                        </div>
                    </div>}
                </CustomBaseCard>
            </div>

            <div className="col-7">
                <CustomBaseCard title="주요 지표">
                    {props.loading && <div className="text-center h-200px pt-20"><Spinner className='mt-6' animation="border" variant={"secondary"} role='status' /></div>}
                    {!props.loading && <div className='row'>
                        <div className="justify-content-end d-flex mb-3 p-0" style={{marginTop:"-2em"}}>
                            <MediaTypeButton className="me-1" onChange={mediaTypeChange} data={props.value} value={selectValue || ""} />
                            <DimensionSetting onChange={setDimension} />
       
                        </div>
                            {dimension.map((v, index)=><DimentsionCard key={index} id={v} value={tableData} />)}
                        </div>}
                </CustomBaseCard>
            </div>
        </div>
    </div>
}
export default ReportCard;

/** 변동율을 배찌로 표시합니다. */
const BadgeDiffPer:React.FC<{base:number, target:number}> = (props)=>{
    const term:number = props.target - props.base;
    const arrow:string = term === 0 ? '' : term < 0 ? '↓' :'↑';
    const color:string = term === 0 ? 'light' : term < 0 ? 'light-danger' :'light-success';

    return <Badge size="xs" className="fs-8 ps-1 pe-1" bg={color} displayInline={false} opacity="0.2" style={{borderRadius:'1em', minWidth:'60px', width:'inherit'}}>
        {arrow} {(Math.abs(term)/(props.base || 1)*100).toFixed(1)+'%'}
    </Badge>
}

/** 변동값 표시합니다. */
const ValueDiff:React.FC<{value:number, unit?:string}> = (props)=>{
    const arrow:string = props.value > 0 ? '+' : '';
    const color:string = props.value === 0 ? 'text-dark' : props.value < 0 ? 'text-danger' :'text-success';
    return <div className={color}>({arrow}{props.value.addComma()}{props.unit === '%' ? '%p' : props.unit})</div>
}

const CellCountView:React.FC<{title?:React.ReactNode|string, base?:number, target?:number, showPercent?:boolean, round?:number}> = (props)=>{
    const unit:string|undefined = props.showPercent===true ? "%" : undefined;
    const time:number = props.showPercent ? 100 : 1;
    const target:number = ((props.target || 0) * time).round(props.round || 0);
    const base:number = ((props.base || 0) * time).round(props.round || 0);

    return <div className="col-lg-6 col-xl-6 col-xxl-4 m-0 p-0">
        <div className="border border-1 border-gray-300 rounded p-5 m-0 ms-3 mb-3">
            <div className="mb-5">
                {props.title}
                <div style={{float:'right'}}>
                    <BadgeDiffPer target={target} base={base} />
                </div>
            </div>
            <div className="d-flex text-right align-items-baseline">
                <div className="fs-3 fw-bolder me-3">{target.addComma()}{unit}</div>
                <ValueDiff value={(target - base).round(props.round || 0)} unit={unit} />
            </div>
            <div className="text-gray-400">비교기간 {base.addComma()}{unit}</div>
        </div>
    </div>
};


const MediaLegendItem:React.FC<{value?:number, title?:string, type?:string, color?:string, checked?:boolean, onClick?:()=>void}> = (props)=>{
    return <>
        <div className={clsx("d-flex w-300px fw-bolder bg-hover-light rounded p-2 ps-1", props.checked ? 'bg-gray-300' :'')} onClick={props.onClick}>
            <div className="d-flex col-9">
                <div className={clsx('mt-1 me-2')}
                    style={{
                        width:'14px', height:'14px', minWidth:'14px', minHeight:'14px',
                        borderRadius:'4px', 
                        backgroundColor:props.color || getMediaTypeColor(props.type?.toLocaleUpperCase()),
                    }}></div>
                <Ellipsis className={clsx(props.checked ? "text-gray-900" : "text-gray-600")} style={{width:'210px'}}>{props.title || props.type}</Ellipsis>
            </div>
            <div className="col-3 text-right">{props.value?.round(1).addComma()}%</div>
        </div>
    </>
}


/** 선택한 clients의 Sum을 반환합니다. */
const getClientsData = (clients:DashBoardStatTableClientResType[], deviceTypes?:string[]):DashBoardStatTableClientType=>{
    const week1 = mergeDashboardReportData(clients.map((v)=>v.week1));
    const week2 = mergeDashboardReportData(clients.map((v)=>v.week2));
    // console.log({clients, week1, week2});
    return {week1: statDashboardReportDataExtension(week1), week2: statDashboardReportDataExtension(week2)};
}


const DimentsionCard:React.FC<{id:string, value:DashBoardStatTableClientType}> = (props)=>{
    const option:OptionType|undefined = Dimension.getOptions().find((v)=>v.value===props.id);
    let target:number|undefined = 0;
    let base:number|undefined = 0;
    let round:number|undefined = undefined;
    let showPercent:boolean|undefined = undefined;

    switch(option?.value){
        case Dimension.options.impressions.value : 
            target = props.value?.week1.impressions;
            base = props.value?.week2?.impressions;
            break;

        case Dimension.options.clicks.value : 
            target = props.value?.week1.clicks;
            base = props.value?.week2.clicks;
            break;
        
        case Dimension.options.cost.value : 
            target = props.value?.week1.cost;
            base = props.value?.week2.cost;
            break;

        case Dimension.options.purchase.value : 
            target = props.value?.week1.purchase;
            base = props.value?.week2.purchase;
            break;

        case Dimension.options.purchaseValue.value : 
            target = props.value?.week1.purchaseValue;
            base = props.value?.week2.purchaseValue;
            break;

        case Dimension.options.videoView.value : 
            target = props.value?.week1.videoView;
            base = props.value?.week2.videoView;
            break;

        case Dimension.options.ctr.value : 
            target = props.value?.week1.ctr;
            base = props.value?.week2.ctr;
            round = 2;
            showPercent = true;
            break;

        case Dimension.options.cvr.value : 
            target = props.value?.week1.cvr;
            base = props.value?.week2.cvr;
            round = 2;
            showPercent = true;
            break;

        case Dimension.options.vtr.value : 
            target = props.value?.week1.vtr;
            base = props.value?.week2.vtr;
            round = 2;
            showPercent = true;
            break;

        case Dimension.options.roas.value : 
            target = props.value?.week1.roas;
            base = props.value?.week2.roas;
            showPercent = true;
            break;

        case Dimension.options.cpc.value : 
            target = props.value?.week1.cpc;
            base = props.value?.week2.cpc;
            break;

        case Dimension.options.cpm.value : 
            target = props.value?.week1.cpm;
            base = props.value?.week2.cpm;
            break;

        case Dimension.options.cpv.value : 
            target = props.value?.week1.cpv;
            base = props.value?.week2.cpv;
            break;
    }

    return <CellCountView
        title={option?.label}
        target={target}
        base={base}
        round={round}
        showPercent={showPercent}
    />;
}