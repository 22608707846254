import Code from "./Code";

export default class NospCode extends Code{
    public static readonly data = {

        ////옵션선택용 //
        options:{
            ExplorerLevel:{
                campaign:   {value:'campaigns',     label:'캠페인'},
                adgroup:    {value:'ad-groups',     label:'광고라인'},
                creative:   {value:'creatives',     label:'광고소재'},
                ad:         {value:'ads',           label:'집행소재'},
                keyword:    {value:'keywords',      label:'키워드'},
                hour:       {value:'hour',          label:'시간대'},
                placement:  {value:'placement',     label:'노출지역'},
            },
            campaignDimensionType: {
                default:    {value: '-',            label: '기본'},
                hour:       {value: 'hour',         label: '시간별'},
                placement:  {value: 'placement',    label: '노출영역별'},
            },
            keywordDimensionType: {
                default:    {value: '-',            label: '기본'},
                celement:   {value: 'celement',     label: '소재요소별'},
            },
        },

        assets : {
        },

        type:{
            /** 캠페인 목적 */
            campaignType : {
                conversion          : {value : 'conversion',        label : '웹사이트 전환'},
                web_site_traffic    : {value : 'web_site_traffic',  label : '웹사이트 트래픽'},
                install_app         : {value : 'install_app',       label : '앱 설치'},
                shopping            : {value : 'shopping',          label : '쇼핑 프로모션'},
                watch_video         : {value : 'watch_video',       label : '동영상 조회'},
                catalog             : {value : 'catalog',           label : '카탈로그 판매'},
                lead                : {value : 'lead',              label : '??'},
            },

            /** 게제 위치 */
            placementType: {
                band            : {value : 'band',              label : '네이버 패밀리 매체 > 피드 영역'},
                f_banner        : {value : 'f_banner',          label : '네이버 패밀리 매체 > 배너 영역'},
                f_smartchannel  : {value : 'f_smartchannel',    label : '네이버 패밀리 매체 > 스마트채널'},
                m_banner        : {value : 'm_banner',          label : '네이버 > 배너 영역 > 서비스 통합'},
                m_feed          : {value : 'm_feed',            label : '네이버 > 피드 영역'},
                m_main          : {value : 'm_main',            label : '네이버 > 배너 영역 > 네이버 메인'},
                m_smartchannel  : {value : 'm_smartchannel',    label : '네이버 > 스마트채널'},
                nw_banner       : {value : 'nw_banner',         label : '네이버 퍼포먼스 네트워크 > 배너 영역'},
                nw_smartchannel : {value : 'nw_smartchannel',   label : '네이버 퍼포먼스 네트워크 > 스마트채널'},
                n_communication : {value : 'n_communication',   label : '네이버 > 커뮤니케이션 영역'},
                n_instream      : {value : 'n_instream',        label : '네이버 > 인스트림 영역'},
                n_shopping      : {value : 'n_shopping',        label : '네이버 > 쇼핑 영역'},
            },
            /** 소재타입 */
            adType : {
                single_image    : {value : 'single_image',      label : '네이티브 이미지'},
                multiple_image  : {value : 'multiple_image',    label : '이미지 슬라이드'},
                single_video    : {value : 'single_video',      label : '동영상'},
                image_banner    : {value : 'image_banner',      label : '이미지 배너'},
                catalog         : {value : 'catalog',           label : '카탈로그'},
            },

            /** 과금유형 */
            paymentType: {
                CPT    : {value : 'CPT',      label : 'CPT'},
                CPM    : {value : 'CPM',      label : 'CPM'},
            },
        },

    }

};

