

/** 리포트 결과 배열타입 */
export interface StatReportResultArrayType<T=StatReportResType, V=StatReportInfoType> {
    info: V;
    performance: T;
}

/**  리포트 결과 타입 */
export interface StatReportResultType<T=StatReportResType> {
    info?: StatReportInfoType;
    total?: T[];
    daily?: T[];
    performances?: T[];
}

/** Explorer 상세보기 타입 */
export interface StatReportInfoType{
    adId?: string;
    adName?: string;
    adGroupId?: string;
    adGroupName?: string;
    campaignId?: string;
    campaignName?: string;
    objective?: string;
    objectiveName?: string;
    advertisingChannelSubType?: string;
    advertisingChannelSubTypeName?: string;
    googleAdgroupType?: string;
    googleAdgroupTypeName: string;
}

/** 리포트 데이터 타입, key - entry ID or Date */
export interface StatReportResType{
    key: string;
    clicks: number;
    frequency: number;
    impressions: number;
    reach: number;
    result: number;
    cost: number;
    uniqueCpc: number;
    reachValue: number;
    uniqueClicks: number;
    linkClick: number;
    postEngagement: number;
    postComment: number;
    postSave: number;
    postShare: number;
    postReaction: number;
    pageEngagement: number;
    like: number;
    photoView: number;
    videoViewStart: number;
    videoView25: number;
    videoView50: number;
    videoView75: number;
    videoView95: number;
    videoViewEnd: number;
    videoPlay3s: number;
    thruPlay: number;
    likeValue: number;
    videoAbove3sValue: number;
    thruPlayValue: number;
    purchase: number;
    purchaseValue: number;
    webPurchase: number;
    webPurchaseValue: number;
    appMobilePurchase: number;
    appMobilePurchaseValue: number;
    facebookPurchase: number;
    facebookPurchaseValue: number;
    completeRegistration: number;
    webCompleteRegistration: number;
    appMobileCompleteRegistration: number;
    installs: number;
    installsPc: number;
    installsMobile: number;
    addToCart: number;
    webAddToCart: number;
    appMobileAddToCart: number;
    activateApp: number;
    appMobileActivateApp: number;
    installsValue: number;
    
    ctr: number;
    cpc: number;
    cpm: number;
    cpr: number;
    uniqueCtr: number;
    costPerUniqueClick: number;
    costPerPageLike: number;
    costPerVideoView3s: number;
    costPerVideoView15s: number;
    costPerInstall: number;
}

/**
 * 리포트 컬럼 처리용 키
 */
export enum StatReportResKeys{
    key = 'key',
    clicks = 'clicks',
    frequency = 'frequency',
    impressions = 'impressions',
    reach = 'reach',
    result = 'result',
    cost = 'cost',
    uniqueCpc = 'uniqueCpc',
    reachValue = 'reachValue',
    uniqueClicks = 'uniqueClicks',
    linkClick = 'linkClick',
    postEngagement = 'postEngagement',
    postComment = 'postComment',
    postSave = 'postSave',
    postShare = 'postShare',
    postReaction = 'postReaction',
    pageEngagement = 'pageEngagement',
    like = 'like',
    photoView = 'photoView',
    videoViewStart = 'videoViewStart',
    videoView25 = 'videoView25',
    videoView50 = 'videoView50',
    videoView75 = 'videoView75',
    videoView95 = 'videoView95',
    videoViewEnd = 'videoViewEnd',
    videoPlay3s = 'videoPlay3s',
    thruPlay = 'thruPlay',
    likeValue = 'likeValue',
    videoAbove3sValue = 'videoAbove3sValue',
    thruPlayValue = 'thruPlayValue',
    purchase = 'purchase',
    purchaseValue = 'purchaseValue',
    webPurchase = 'webPurchase',
    webPurchaseValue = 'webPurchaseValue',
    appMobilePurchase = 'appMobilePurchase',
    appMobilePurchaseValue = 'appMobilePurchaseValue',
    facebookPurchase = 'facebookPurchase',
    facebookPurchaseValue = 'facebookPurchaseValue',
    completeRegistration = 'completeRegistration',
    webCompleteRegistration = 'webCompleteRegistration',
    appMobileCompleteRegistration = 'appMobileCompleteRegistration',
    installs = 'installs',
    installsPc = 'installsPc',
    installsMobile = 'installsMobile',
    addToCart = 'addToCart',
    webAddToCart = 'webAddToCart',
    appMobileAddToCart = 'appMobileAddToCart',
    activateApp = 'activateApp',
    appMobileActivateApp = 'appMobileActivateApp',
    installsValue = 'installsValue',    

    ctr = 'ctr',
    cpc = 'cpc',
    cpm = 'cpm',
    cpr = 'cpr',
    uniqueCtr = 'uniqueCtr',
    costPerUniqueClick = 'costPerUniqueClick',
    costPerPageLik = 'costPerPageLike',
    costPerVideoView3s = 'costPerVideoView3s',
    costPerVideoView15s = 'costPerVideoView15s',
    costPerInstall = 'costPerInstall',
}



export interface ExplorerDetailReqType{
    startDate: string;
    endDate: string;
    dimension?: string;
    searchDimensionWord?: string;
}

/** 네이버 실적데이터 대량 아이디에 대한 리포트 호출 쿼리타입 */
export interface ExplorerPostReqType extends ExplorerDetailReqType{
    ids: string[];
}
export interface ExplorerReqType{
    from: number,
    size: number,
    searchWord: string,
    startDate: string,
    endDate: string
}
